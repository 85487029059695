{
    "Dashboard": "Dashboard",
    "Logistics Managment": "Logistics Managment",
    "Create Post": "Create Post",
    "Post List": "Post List",
    "Query Shipping": "Query Shipping",
    "Price Calculation": "Price Calculation",
    "Wallet Balance": "Wallet Balance",
    "Desi Cargo Regions": "Desi Cargo Regions",
    "Desi Cargo Country": "Desi Cargo Country",
    "Cargo Companies": "Cargo Companies",
    "Logistics Integration": "Logistics Integration",
    "Customs List": "Customs List",
    "Logistics Customers": "Logistics Customers",
    "Setting": "Setting",
    "Desi Regions": "Desi Regions",
    "Cargo Country": "Cargo Country",
    "Loading": "Loading",
    "Cargo Region List": "Cargo Region List",
    "Desi Price Definition": "Desi Price Definition",
    "Dealers and discounts": "Dealers and discounts",
    "Wallet Transactions": "Wallet Transactions",
    "Accounting": "Accounting",
    "Cost Table List": "Cost Table List",
    "Cargo Payments": "Cargo Payments",
    "Dealer List": "Dealer List",
    "Dealer Display": "Dealer Display",
    "Income": "Income",
    "Expense": "Expense",
    "Earning": "Earning",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "$vuetify": {
        "badge": "Badge",
        "noDataText": "No data available",
        "close": "Close",
        "open": "open",
        "loading": "loading",
        "stepper": {
            "next": "Next",
            "prev": "Prev",
            "back": "Back",
            "finish": "Finish",
            "cancel": "Cancel"
        },
        "carousel": {
            "ariaLabel": {
                "delimiter": "delimiter"
            }
        },
        "dataIterator": {
            "loadingText": "Loading item..."
        },
        "dataFooter": {
            "itemsPerPageText": "Items per page:",
            "itemsPerPageAll": "All",
            "pageText": "{0}-{1} of {2}",
            "firstPage": "First Page",
            "prevPage": "Previous Page",
            "nextPage": "Next Page",
            "lastPage": "Last Page"
        },
        "pagination": {
            "ariaLabel": {
                "root": "root",
                "previous": "previous",
                "first": "first",
                "last": "last",
                "next": "next",
                "currentPage": "currentPage",
                "page": "page"
            }
        },
        "input": {
            "clear": "clear",
            "appendAction": "appendAction",
            "prependAction": "prependAction",
            "counterSize": "counterSize",
            "otp": "otp"
        },
        "fileInput": {
            "counterSize": "counterSize"
        },
        "rating": {
            "ariaLabel": {
                "item": "item"
            }
        }
    }
}