<script setup>
import { onMounted, ref, watch } from 'vue'
import { formatFloat, formatNumber, formatDate, fakeAwait } from '@/utils'
import { requiredValidator } from '@/@core/utils/validators'

definePage({
  meta: {
    action: 'read',
    subject: 'dealers-and-discounts',
  },
})

const search = ref('')
const items = ref([])
const isLoading = ref(false)
const isLoadinDealers = ref(false)
const refVForm = ref()
const isEdit = ref(false)
const selectedItem = ref({})
const dealerUsers=ref([])
const regions=ref([])

const formDatas = ref({
  use_type:null,
  user_id:null,
  company_name: null,
  region: null,
  discount_regions: [],
  discount_rate: null,
  package_quantity: null,
  package_weight: null,

})





const headers = computed(() => {
  return [
    { title: 'Process', value: 'process', width: '100' },
    {
      title: 'Name Surname',
      value: 'user.name',
      sortable: true,
    },
    {
      title: 'Exit Region',
      value: 'user.main_address.region.short_code',
      sortable: true,
    },
    {
      title: 'Country',
      value: 'user.main_address.country.trisim',
      sortable: true,
    },

    {
      title: 'Record Type',
      value: 'user.user_module_information.register_type',
      sortable: true,
    },
    {
      title: 'Role',
      value: 'user.role',
      sortable: true,
    },


    {
      title: 'Company Name',
      value: 'user.companies',
      sortable: true,
    },


    { title: 'Arrival Region', value: 'discount_regions', sortable: true },
    { title: 'Discount rate%', value: 'discount_rate', sortable: true },
    { title: 'Number of Packages', value: 'package_quantity', sortable: true },
    {
      title: 'Package Weight',
      value: 'package_weight',
      sortable: true,
    },
    { title: 'Time', value: 'updated_at', sortable: true },
    { title: 'Status', value: 'status' },
  ]
})


const useTypes = [
  { title: 'All', value: -1 },
  { title: 'Customer', value: 0 },
  { title: 'Dealer', value: 1 },
]

const getData = async () => {

  isLoading.value = true
  await $api('dealer_discount', {
    method: 'GET',
    onResponse: ({ response }) => {
      items.value = response._data
    },
  })
  isLoading.value = false
}


const getRegions = async () => {
  isLoading.value = true
  await $api(`get_regions`, {
    method: 'GET',
    onResponse: ({ response }) => {

      regions.value = response._data
    },
  })
  isLoading.value = false
}

const getDealers = async () => {
  isLoadinDealers.value = true
  await $api(`dealer_users/${formDatas.value.use_type}`, {
    method: 'GET',
    onResponse: ({ response }) => {
      formDatas.value.user_id=null
      formDatas.value.company_name= null
      formDatas.value.region= null
      dealerUsers.value = response._data
    },
  })
  isLoadinDealers.value = false
}

watch(
  () => formDatas.value.user_id,
  () => {
    formDatas.value.company_name=null
    if (formDatas.value.user_id) {
      console.log('user_id', formDatas.value.user_id)

      const findUser = dealerUsers.value.find(
        cr => cr.id === formDatas.value.user_id,
      )

      console.log('findUser', findUser)

      if(findUser.companies!=null&&findUser.companies.length>0){
        formDatas.value.company_name = findUser.companies[0].name

      }
      if(findUser.main_address!=null){
        formDatas.value.region = findUser.main_address.region.short_code
      }

    }
  },
)

onMounted(() => {
  getData()
  getRegions()

})

const onSubmit = async () => {
  refVForm.value?.validate().then(async ({ valid: isValid }) => {
    if (isValid) {
      const data = {
        user_id: formDatas.value.user_id,
        discount_regions: formDatas.value.discount_regions,
        discount_rate: formDatas.value.discount_rate,
        package_quantity: formDatas.value.package_quantity,
        package_weight: formDatas.value.package_weight,
      }

      isLoading.value = true
      await $api(`dealer_discount`, {
        method: 'POST',
        body: data,
        onResponse: async ({ response }) => {
          await getData()
        },
      })

      isLoading.value = false
    }
  })
}

const setStatus = async item => {
  isLoading.value = true
  await $api(`dealer_discount/${item.id}`, {
    method: 'GET',
    onResponse: async ({ response }) => {
      await getData()
    },
  })
  isLoading.value = false
}



const onEdit = item => {

  selectedItem.value = { ...item }
  selectedItem.value.discount_regions =  item.discount_regions.map(A=>A.region.id)

  console.log('Edit', selectedItem.value)
  isEdit.value = true
}

const onSave = async() => {
  console.log('Save', selectedItem.value)

  isLoading.value = true

  await $api(`dealer_discount/${selectedItem.value.id}`, {
    method: 'POST',
    params: {
      _method: 'PUT',
    },
    body: selectedItem.value,
    onResponse: async ({ response }) => {
      isEdit.value=false
      await getData()
    },
  })
  isLoading.value = false
}

const onChangeUseType=()=>{
  getDealers()
}
</script>

<template>
  <div>
    <VCard class="mb-2">
      <VForm
        ref="refVForm"
        @submit.prevent="onSubmit"
      >
        <div class="d-flex flex-wrap gap-2 ma-2">
          <div style="width: 12rem">
            <VSelect
              v-model="formDatas.use_type"
              :rules="[requiredValidator]"
              :items="useTypes"
              label="Record Type"
              clearable
              @update:model-value="onChangeUseType"
            />
          </div>
          <div style="width: 12rem">
            <VAutocomplete
              v-model="formDatas.user_id"
              :disabled="formDatas.use_type==null||isLoadinDealers || isLoading"
              :items="dealerUsers"
              item-title="namesurname"
              item-value="id"
              autocomplete="new-password"
              label="Name Surname"
            />
          </div>
          <div style="width: 9rem">
            <VTextField
              v-model="formDatas.company_name"
              disabled
              readonly
              label="Company Name"
            />
          </div>

          <div style="width: 7rem">
            <VTextField
              v-model="formDatas.region"
              disabled
              readonly
              label="Region"
            />
          </div>

          <div style="width: 13rem">
            <VSelect
              v-model="formDatas.discount_regions"
              :items="regions"
              item-title="short_code"
              item-value="id"
              multiple
              label="Arrival Regions"
            />
          </div>


          <div style="width: 10rem">
            <VTextField
              v-model="formDatas.discount_rate"
              :rules="[requiredValidator]"
              :disabled="isLoadinDealers || isLoading"
              prefix="%"
              label="Discount rate%"
              @input="formatFloat(formDatas, { key: 'discount_rate', max: 50,parts: 2 })"
            />
          </div>


          <div style="width: 11rem">
            <VTextField
              v-model="formDatas.package_quantity"
              :rules="[requiredValidator]"
              :disabled="isLoadinDealers || isLoading"
              label="Package Quantity"
              @input="formatNumber(formDatas, { key: 'package_quantity',max: 100000 })"
            />
          </div>
          <div style="width: 11rem">
            <VTextField
              v-model="formDatas.package_weight"
              :rules="[requiredValidator]"
              :disabled="isLoadinDealers || isLoading"
              label="Package weight"
              @input="formatNumber(formDatas, { key: 'package_weight',max: 100000 })"
            />
          </div>
          <VBtn
            :disabled="isLoadinDealers || isLoading"
            color="primary"
            class="flex-grow"
            type="submit"
          >
            Define
          </VBtn>
        </div>
      </VForm>
    </VCard>

    <VCard>
      <div class="d-flex flex-wrap gap-2 ma-2">
        <div style="width: 12rem">
          <VTextField
            v-model="search"
            label="Search"
            prepend-inner-icon="tabler-search"
            class="flex-grow"
          />
        </div>
        <VSpacer />
      </div>

      <VDataTable
        :headers="headers"
        :items="items"
        :loading="isLoading"
      >
        <template #[`item.process`]="{ item }">
          <VRow>
            <VBtn
              class="me-2"
              variant="tonal"
              size="small"
              icon="tabler-edit"
              @click="onEdit(item)"
            />
            <VSwitch
              v-model="item.status"
              :true-value="1"
              :false-value="0"
              @click="setStatus(item)"
            />
          </VRow>
        </template>

        <template #[`item.user.name`]="{ item }">
          <div>
            {{ `${item.user.name} ${item.user.surname}` }}
          </div>
        </template>

        <template #[`item.discount_regions`]="{ item }">
          <div>
            {{ `${item.discount_regions.map(A=>A.region.short_code)}` }}
          </div>
        </template>

        <template #[`item.user.companies`]="{ item }">
          <div>
            {{ `${item.user.companies.map(A=>A.name)}` }}
          </div>
        </template>

        <template #[`item.updated_at`]="{ item }">
          <div>
            {{ formatDate(item.updated_at) }}
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          <VChip :color="item.status == 1 ? 'success' : 'error'">
            {{ item.status == 1 ? 'Active' : 'Passive' }}
          </VChip>
        </template>
      </VDataTable>
    </VCard>
    <XDialog
      v-model:isDialogVisible="isEdit"
      title="Update"
      :max-width="400"
    >
      <template #content>
        <VRow

          class="ma-2"
        >
          <VTextField
            v-model="selectedItem.package_quantity"
            :disabled="isLoading"
            class="ma-2"
            label="Package Quantity"
            @input="formatNumber(selectedItem, { key: 'package_quantity' })"
          />
          <VTextField
            v-model="selectedItem.package_weight"
            :disabled="isLoading"
            class="ma-2"
            label="Package Weight"
            @input="formatNumber(selectedItem, { key: 'package_weight' })"
          />
        </VRow>
        <VRow class="ma-2">
          <VTextField
            v-model="selectedItem.discount_rate"
            :disabled="isLoading"
            class="ma-2"
            label="Discount rate"
            prefix="%"
            @input="formatFloat(selectedItem, { key: 'discount_rate', max: 50,parts: 2 })"
          />
          <VSelect
            v-model="selectedItem.discount_regions"
            :items="regions"
            item-title="short_code"
            item-value="id"
            multiple
            label="Arrival Regions"
          />
        </VRow>
        <div class="text-center">
          <VBtn
            :loading="isLoading"
            @click="onSave"
          >
            Save
          </VBtn>
        </div>
      </template>
    </XDialog>
  </div>
</template>
