<script setup>
import { $api } from '@/utils/api'
import { onMounted, ref } from 'vue'

const regions = ref([])
const cargoCompanies = ref([])
const desiRegions = ref([])
const search = ref('')
const isEdit = ref(false)
const isLoading = ref(false)
const errorMessage = ref('')
const isError = ref(false)

definePage({
  meta: {
    action: 'read',
    subject: 'cargo-region-list',
  },
})

const headers = ref([
  { title: 'Process', value: 'process', width: '100' },
  { title: 'Arrival Region', value: 'arrivalRegion', sortable: true },
  { title: 'Exit Region', value: 'exitRegion', sortable: true },
  { title: 'Cargo Company', value: 'cargoCompany', sortable: true },
  { title: 'Desi Region', value: 'desiRegion', sortable: true },
  { title: 'Status', value: 'status', sortable: true },
])

const items = ref([])

const item = ref({
  id: null,
  region_id: null,
  cargo_company_id: null,
  desi_region_id: null,
  status: 1,
})


const getData = async () => {
  isLoading.value = true
  await $api('cargo_region', {
    method: 'GET',
    onResponse: ({ response }) => {
      items.value = response._data
    },
  })
  isLoading.value = false
}

const onEdit = selectedItem => {
  console.log('Selected Item', selectedItem)
  if (selectedItem) {
    item.value = { ...selectedItem }
  } else {
    item.value = {
      id: null,
      region_id: null,
      cargo_company_id: null,
      desi_region_id: null,
      status: 1,
    }
  }
  console.log('Edit', item.value)
  isEdit.value = true
}


const getConsts = async () => {
  await $api('desi_region_consts', {
    method: 'GET',
    onResponse: ({ response }) => {
      regions.value = response._data.regions
      desiRegions.value = response._data.desiRegions
      cargoCompanies.value = response._data.cargoCompanies
    },
  })
}



onMounted(() => {
  getConsts()
  getData()

})

const onSave = async () => {
  isEdit.value = false
  isLoading.value = true
  console.log( item.value)
  await $api(`cargo_region${item.value.id ? `/${item.value.id}` : ''}`, {
    method: 'POST',
    params: {
      _method: item.value.id ? 'PUT' : 'POST',
    },
    body: item.value,
    onResponse: async ({ response }) => {
      console.log('Response status', response.status)
      if (response.status > 400) {
        isError.value = true
        errorMessage.value = response._data.message

        return
      } else {
        await getData()
      }


    }, onResponseError: ({ response }) => {
      console.log('Error', response)
    },
  })
  isLoading.value = false
}

const checkValid = computed(() => {
  return !item.value.region_id || !item.value.cargo_company_id || !item.value.desi_region_id
})
</script>


<template>
  <div>
    <VCard class="pa-2">
      <div class="d-flex flex-wrap">
        <div style="width: 12rem">
          <VTextField
            v-model="search"
            label="Search"
            clearable
          />
        </div>
        <VSpacer />
        <VBtn
          prepend-icon="tabler-plus"
          @click="onEdit(null)"
        >
          Add
        </VBtn>
      </div>
      <VDataTable
        :loading="isLoading"
        :items="items"
        :headers="headers"
        :search="search"
        hover
        density="compact"
      >
        <template #[`item.process`]="{ item }">
          <VBtn
            class="me-2"
            variant="tonal"
            size="small"
            icon="tabler-edit"
            @click="onEdit(item)"
          />
        </template>

        <template #[`item.arrivalRegion`]="{ item }">
          <span>{{ item.region?.short_code || '' }}</span>
        </template>

        <template #[`item.exitRegion`]="{ item }">
          <span>{{ item.cargo_company?.region?.short_code || '' }}</span>
        </template>

        <template #[`item.cargoCompany`]="{ item }">
          <span>{{ item.cargo_company?.name || '' }}</span>
        </template>

        <template #[`item.desiRegion`]="{ item }">
          <span>{{ item.desi_region?.desiregion || '' }}</span>
        </template>

        <template #[`item.status`]="{ item }">
          <VChip :color="item.status == 1 ? 'success' : 'error'">
            {{ item.status == 1 ? 'Active' : 'Passive' }}
          </VChip>
        </template>
      </VDataTable>
    </VCard>
    <XDialog
      v-model:isDialogVisible="isEdit"
      text-center
      :max-width="800"
      :title="item.id ? 'Update Cargo Region' : 'Add Cargo Region'"
    >
      <template #content>
        <VCard class="mb-2 text-center">
          <VRow class="ma-2">
            <VCol
              cols="6"
              sm="4"
            >
              <VSelect
                v-model="item.region_id"
                label="Arrival Region"
                :items="regions"
                item-title="short_code"
                item-value="id"
                clearable
              />
            </VCol>

            <VCol
              cols="6"
              sm="4"
            >
              <VSelect
                v-model="item.cargo_company_id"
                label="Cargo Company"
                :items="cargoCompanies"
                item-title="name"
                item-value="id"
                clearable
              />
            </VCol>


            <VCol
              cols="6"
              sm="4"
            >
              <VSelect
                v-model="item.desi_region_id"
                label="Desi Region"
                :items="desiRegions"
                item-title="desiregion"
                item-value="id"
                clearable
              />
            </VCol>

            <VCol
              cols="6"
              sm="4"
            >
              <VSwitch
                v-model="item.status"
                label="Status"
                :true-value="1"
                :false-value="0"
                class="ma-2"
              />
            </VCol>
          </VRow>



          <div class=" text-center">
            <VBtn
              :disabled="checkValid"
              class="ma-2"
              color="primary"
              @click="onSave"
            >
              Save
            </VBtn>
            <VBtn
              class="ma-2"
              color="secondary"
              @click="isEdit = false"
            >
              Close
            </VBtn>
          </div>
        </VCard>
      </template>
    </XDialog>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="errorMessage"
      ok-title="OK"
    />
  </div>
</template>
