<script setup>
import { $api } from '@/utils/api'
import { onMounted, ref } from 'vue'

const search = ref('')
const isEdit = ref(false)
const isLoading = ref(false)
const errorMessage = ref('')
const isError = ref(false)
const regions = ref([])
const region = ref()
const loadingRegions = ref(false)
const countries = ref([])
const country = ref()
const loadingCountries = ref(false)
const desiregions = ref([])
const desiregion = ref()
const loadingDesiregions = ref(false)

definePage({
  meta: {
    action: 'read',
    subject: 'desi-cargo-country',
  },
})

const headers = ref([
  { title: 'Process', value: 'process', width: '100px' },
  { title: 'Desiregion', value: 'desi_region.desiregion', sortable: true },
  { title: 'Country Code', value: 'country.country_code', sortable: true },
  { title: 'Country Phone Code', value: 'country.country_phone_code', sortable: true },
  { title: 'Country', value: 'country.trisim', sortable: true },
  { title: 'Status', value: 'status', sortable: true },
])

const items = ref([])

const item = ref({
  id: null,
  cargo_region_id: null,
  region_id: null,
  country_id: null,
  status: 1,
})

const getData = async () => {
  isLoading.value = true
  await $api('cargo_country', {
    method: 'GET',
    onResponse: ({ response }) => {
      items.value = response._data
      console.log('Response', items.value)
    },
  })
  isLoading.value = false
}

const onEdit = selectedItem => {
  regions.value = []
  countries.value = []
  desiregions.value = []
  region.value = null
  country.value = null
  desiregion.value = null
  if (selectedItem) {
    item.value = { ...selectedItem }
  } else {
    item.value = {
      id: null,
      desi_region_id: null,
      region_id: null,
      country_id: null,
      status: 1,
    }
  }
  console.log('Edit', item.value)
  getRegions()
  getDesiRegions()
  isEdit.value = true
}

onMounted(() => {
  getData()
})

const getRegions = async () => {
  loadingRegions.value = true
  await $api('get_regions', {
    method: 'GET',
    onResponse: async ({ response }) => {
      regions.value = response._data

      const find = regions.value.find(r => r.id == item.value.region_id)
      if (find) {
        region.value = find.id
        await getCountries()
      }
    },
  })
  loadingRegions.value = false
}

const getCountries = async () => {
  loadingCountries.value = true
  await $api('get_countries', {
    method: 'GET',
    params: {
      region_id: region.value,
    },
    onResponse: ({ response }) => {
      countries.value = response._data

      const find = countries.value.find(r => r.id == item.value.country_id)
      if (find) {
        country.value = find.id
      }
    },
  })
  loadingCountries.value = false
}

const getDesiRegions = async () => {
  loadingDesiregions.value = true
  await $api('get_desi_regions', {
    method: 'GET',
    onResponse: ({ response }) => {
      desiregions.value = response._data

      const find = desiregions.value.find(
        r => r.id == item.value.desi_region_id,
      )

      if (find) {
        desiregion.value = find.id
      }
    },
  })
  loadingDesiregions.value = false
}

const onSave = async () => {
  isEdit.value = false
  isLoading.value = true
  item.value.desi_region_id = desiregion.value
  item.value.region_id = region.value
  item.value.country_id = country.value

  await $api(`cargo_country${item.value.id ? `/${item.value.id}` : ''}`, {
    method: 'POST',
    params: {
      _method: item.value.id ? 'PUT' : 'POST',
    },
    body: item.value,
    onResponse: async ({ response }) => {
      console.log('Response status', response.status)
      if (response.status > 400) {
        isError.value = true
        errorMessage.value = response._data.message

        return
      } else {
        await getData()
      }
    },
    onResponseError: ({ response }) => {
      console.log('Error', response)
    },
  })
  isLoading.value = false
}

const onRegionChange = async () => {
  country.value = null
  await getCountries()
}
</script>


<template>
  <div>
    <VCard class="pa-2">
      <div class="d-flex flex-wrap">
        <div style="width: 12rem">
          <VTextField
            v-model="search"
            label="Search"
            clearable
          />
        </div>
        <VSpacer />
        <VBtn
          prepend-icon="tabler-plus"
          @click="onEdit(null)"
        >
          Add
        </VBtn>
      </div>
      <VDataTable
        :loading="isLoading"
        :items="items"
        :headers="headers"
        :search="search"
        hover
        density="compact"
      >
        <template #[`item.status`]="{ item:slotItem }">
          <VChip :color="slotItem.status == 1 ? 'success' : 'error'">
            {{ slotItem.status == 1 ? 'Active' : 'Passive' }}
          </VChip>
        </template>




        <template #[`item.process`]="{ item }">
          <VBtn
            class="me-2"
            variant="tonal"
            size="small"
            icon="tabler-edit"
            @click="onEdit(item)"
          />
        </template>
      </VDataTable>
    </VCard>
    <XDialog
      v-model:isDialogVisible="isEdit"
      text-center
      :max-width="800"
      :title="item.id ? 'Update Desiregion Country' : 'Add Desiregion Country'"
    >
      <template #content>
        <VRow class="ma-2">
          <VCol
            cols="6"
            sm="4"
          >
            <VSelect
              v-model="region"
              :loading="loadingRegions"
              label="Regions"
              :items="regions"
              item-title="short_code"
              item-value="id"
              @update:model-value="onRegionChange"
            />
          </VCol>

          <VCol
            cols="6"
            sm="4"
          >
            <VSelect
              v-model="country"
              :loading="loadingCountries"
              label="Country"
              :items="countries"
              item-title="trisim"
              item-value="id"
            />
          </VCol>

          <VCol
            cols="6"
            sm="4"
          >
            <VSelect
              v-model="desiregion"
              :loading="loadingDesiregions"
              label="Desi Regions"
              :items="desiregions"
              item-title="desiregion"
              item-value="id"
            />
          </VCol>

          <VCol
            cols="6"
            sm="4"
          >
            <VSwitch
              v-model="item.status"
              label="Status"
              :true-value="1"
              :false-value="0"
              class="ma-2"
            />
          </VCol>
        </VRow>

        <div class="text-center">
          <VBtn
            :disabled="!region || !country || !desiregion"
            class="ma-2"
            color="primary"
            @click="onSave"
          >
            Save
          </VBtn>
          <VBtn
            class="ma-2"
            color="secondary"
            @click="isEdit = false"
          >
            Close
          </VBtn>
        </div>
      </template>
    </XDialog>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="errorMessage"
      ok-title="OK"
    />
  </div>
</template>
