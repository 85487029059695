<script setup>
import { computed, onMounted, ref, watch } from "vue"
import odeme1 from '@/../images/ödeme-1.png'
import odeme2 from '@/../images/ödeme-2.png'
import odeme3 from '@/../images/1-63.png'
import odeme4 from '@/../images/1-62.png'
import paytr from '@/../images/1-27.png'
import paypal from '@/../images/1-26.png'
import { requiredValidator ,creditCardValidator } from "@/@core/utils/validators"
import { formatFloat } from "@/utils"
import { get } from "@vueuse/core"
import { useRoute } from 'vue-router'


definePage({
  meta: {
    action: 'read',
    subject: 'wallet-balance',
  },
})

const router = useRouter()

const route = useRoute()
const isPaymentLoading=ref(false)
const selectedPayment=ref(0)
const wallet=ref({ balance : 0,transactions:[] })
const isLoading=ref(false)
const inOutTabIndex=ref(0)
const isPayment=ref(false)
const uuid=ref('')
const note=ref('')
const regionPaments=ref({ regionBanks:[],regionPayments:[] })
const isError=ref(false)
const errorMsg=ref('')

const iframeToken=ref()
const paypalUrl=ref()

const isDeposit=ref(false)
const deposit=ref({ balance : 0 })

const isWithdrawal=ref(false)
const withdrawal=ref({ balance : 0,note:'' })

const currentStep=ref(0)
const selectedPaymentMethod=ref(0)

const selectedTrans=ref({})
const isCancelTrans=ref(false)



watch(selectedPaymentMethod,val=>{
  console.log(val)
})

const getPaymentStatus = status => {
  switch (status) {
  case 0:
    return 'Pending'
  case 1:
    return 'Approved'
  case 2:
    return 'Rejected'
  case 3:
    return 'Canceled'
  default:
    return 'Pending'
  }
}

const checkoutSteps = [
  {
    title: 'Payment Summary',
    icon: 'tabler-list-check',
  },
  {
    title: 'Payment Method',
    icon: 'tabler-credit-card-pay',
  },
  {
    title: 'Payment Information',
    icon: 'tabler-info-square',
  },
  {
    title: 'Approve',
    icon: 'tabler-checkbox',
  },
]



const paymentMethods=[
  {
    title: 'Credit Card Payment',
    image: odeme1,
    value:0,
  },
  {
    title: 'Bank Transfer',
    image: odeme2,
    value:1,

  },
  {
    title: 'Payment on Delivery',
    image: odeme3,
    value:2,

  },
  {
    title: 'Payment with Bitcoin',
    image: odeme4,
    value:3,
  },
]


const incomingHeaders=computed(()=>{
  return [
    { title: 'Process', value: 'process' },
    { title: 'Uid', value: 'uuid' },
    { title: 'Date Time', value: 'transaction_date' },
    { title: 'Amount', value: 'amount' },
    { title: 'Description', value: 'description' },
    { title: 'Payment Method', value: 'payment_method' },
    { title: 'Status', value: 'status' },


  ]
})

const outgoingHeaders=computed(()=>{
  return [
    { title: 'Process', value: 'process' },
    { title: 'Uid', value: 'uuid' },
    { title: 'Date Time', value: 'transaction_date' },
    { title: 'Amount', value: 'amount' },
    { title: 'Description', value: 'description' },
    { title: 'Status', value: 'status' },


  ]
})


const getWallet = async () => {
  isLoading.value = true
  await $api('wallets', {
    method: 'GET',
    onResponse: ({ response }) => {
      wallet.value = response._data.wallet
      regionPaments.value.regionPayments = response._data.regionPayments
      regionPaments.value.regionBanks = response._data.regionBanks

    },
  })
  isLoading.value = false
}


const getIncomingTrans = computed(() => {
  return wallet.value.transactions.filter(v=>v.type==1)
})

const getOutgoingTrans = computed(() => {
  return wallet.value.transactions.filter(v=>v.type==2)
})

onMounted(() => {
  getWallet()

  const paymentStatus = route.query.payment
  if(paymentStatus){
    if(paymentStatus=='success'){
      isError.value=true
      errorMsg.value='Payment is Successful'
    }else{
      isError.value=true
      errorMsg.value='Payment is failed'
    }
    errorMsg.value='Payment is successful'
    console.log(paymentStatus)
  }
})


const onDeposit=()=>{
  deposit.value.balance=0
  isDeposit.value=true
}


const onDepositConfirm=()=>{
  iframeToken.value=null
  selectedPayment.value=0
  isDeposit.value=false
  currentStep.value=0
  selectedPaymentMethod.value=0
  isPayment.value=true
}


const onWithdrawalConfirm=async ()=>{
  isWithdrawal.value=false
  isLoading.value = true
  await $api('wallet_withdrawal', {
    method: 'POST',
    body: {
      balance: withdrawal.value.balance,
      description: withdrawal.value.note,
    },
    onResponse: ({ response }) => {
      getWallet()

    },
  })
  isLoading.value = false

}

const getTransUID = async () => {
  isLoading.value = true
  await $api('get_trans_uid', {
    method: 'GET',
    onResponse: ({ response }) => {
      uuid.value = response._data.uid

    },
  })
  isLoading.value = false
}


const onSubmit=()=>{
  console.log('onSubmit')
  isPayment.value=false
}

const onPaymentStepBack=()=>{
  if(currentStep.value==2&&selectedPaymentMethod.value==0){
    console.log("test")
    if(selectedPayment.value!=0){
      selectedPayment.value=0

      return
    }

  }
  currentStep.value--
}


const onPaymentStep =async () => {
  if(regionPaments.value.regionPayments.length==0&&selectedPaymentMethod.value==0&& currentStep.value==1){
    isError.value=true
    errorMsg.value='There is no bank information for the selected region'

    return
  }
  if(currentStep.value==2&&selectedPaymentMethod.value==0){
    console.log("test")
    if(selectedPayment.value!=0){
      selectedPayment.value=0

      return
    }

  }else if(currentStep.value==2&&selectedPaymentMethod.value==1){
    currentStep.value++
    if(currentStep.value==3&&selectedPaymentMethod.value==1){
      iframeToken.value=null
      isLoading.value = true
      await $api('payment_bank', {
        method: 'POST',
        body: {
          uuid: uuid.value,
          balance: deposit.value.balance,
          description: note.value,
        },
        onResponse: ({ response }) => {
          getWallet()
          deposit.value.balance=0
        },
      })
      isLoading.value = false
    }

  }else{
    currentStep.value++
    console.log(currentStep.value)
    if(currentStep.value==2&&selectedPaymentMethod.value==1){
      await getTransUID()
    }
  }
  console.log(currentStep.value)

}

const onSelectBank=bank=>{
  console.log(bank)
}


const copyToClipboard=text=>{
  navigator.clipboard.writeText(text)
}


const onWithdrawal=()=>{
  withdrawal.value.balance=0
  withdrawal.value.note=''
  isWithdrawal.value=true

}


const onCancel=item=>{
  console.log(item)
  selectedTrans.value=item
  isCancelTrans.value=true
}

const onCancelTrans=async ()=>{
  isCancelTrans.value=false

  isLoading.value = true
  await $api('cancel_trans', {
    method: 'POST',
    body: {
      id: selectedTrans.value.id,
    },
    onResponse: ({ response }) => {
      if(response.status==403){
        errorMsg.value=response._data.message
        isError.value=true
      }
      getWallet()
    },
  })
  isLoading.value = false

}

const onSelectedPayMethod=async id=>{
  console.log('onSelectedPayMethod')
  iframeToken.value=null
  selectedPayment.value=id
  isPaymentLoading.value=true
  await $api('payment', {
    method: 'POST',
    body: {
      payment_type: selectedPayment.value,
      balance: deposit.value.balance,
    },
    onResponse: ({ response }) => {
      console.log(response._data)
      if(selectedPayment.value==1){
        iframeToken.value=response._data
      }else if(selectedPayment.value==2){
        // paypalUrl.value=response._data.approve_url
        window.location.href=response._data.approve_url
      }


      /*
      setInterval(function () {
        resizeIframe()
      }, 1000)
        */
    },
  })
  isPaymentLoading.value=false
}



const getSteps =computed(()=>{
  if(selectedPaymentMethod.value==0&&currentStep.value==2){
    return checkoutSteps.filter((v,i)=>i!=3)
  }

  return checkoutSteps
})


const onOkError=()=>{
  console.log('onOkError')
  if(route.query.hasOwnProperty('payment')||route.query.hasOwnProperty('failed')){
    router.push({ name: 'wallet-balance' })
  }
}

const getIframeSrc=computed(()=>{
  if(!iframeToken.value){
    return null
  }

  return `https://www.paytr.com/odeme/guvenli/${iframeToken.value}`
})
</script>


<template>
  <VCard class="pa-2">
    <div v-if="!isLoading&&wallet">
      <div class="d-flex justify-center">
        <VCard
          class="pa-3"
          max-width="30rem"
          variant="outlined"
        >
          <div>
            <VCardTitle class="text-center font-weight-black">
              Balance
            </VCardTitle>
            <VCardTitle class="text-center font-weight-bold">
              {{ `${wallet.balance} $` }}
            </VCardTitle>

            <VCardSubtitle
              v-if="wallet.locked_balance>0"
              class="text-center  text--success"
            >
              {{ `Locked : ${wallet.locked_balance} $` }}
            </VCardSubtitle>


            <VRow>
              <VCol>
                <VBtn
                  color="success"
                  @click="onDeposit"
                >
                  Deposit
                </VBtn>
              </VCol>
              <VCol>
                <VBtn
                  :disabled="wallet.balance<0.01"
                  color="primary"
                  @click="onWithdrawal"
                >
                  Withdrawal
                </VBtn>
              </VCol>
            </VRow>
          </div>
        </VCard>
      </div>
      <div class="d-flex justify-center mt-2">
        <VTabs
          v-model="inOutTabIndex"
        >
          <VTab>
            Incoming Transactions
          </VTab>
          <VTab>
            Outgoing Transactions
          </VTab>
        </VTabs>
      </div>
      <div>
        <VCard
          variant="outlined"
        >
          <VDataTable
            :items="inOutTabIndex==0 ? getIncomingTrans :getOutgoingTrans "
            :headers="inOutTabIndex==0 ? incomingHeaders :outgoingHeaders "
          >
            <template #[`item.status`]="{ item }">
              <VChip
                :color="item.status === 0 ? 'warning' : item.status === 1 ? 'success':item.status === 2 ? 'info' : 'error' "
                class="text-capitalize"
              >
                {{ getPaymentStatus(item.status) }}
              </VChip>
            </template>
            <template #[`item.process`]="{ item } ">
              <VBtn
                v-if="item.status==0 "
                class="me-2"
                variant="tonal"
                color="error"
                size="small"
                icon="tabler-circle-letter-x"
                @click="onCancel(item)"
              />
            </template>

            <template #[`item.payment_method`]="{ item } ">
              {{ item.payment_method.name }}
            </template>
          </VDataTable>
        </VCard>
      </div>
    </div>
    <XDialog
      v-model:isDialogVisible="isPayment"
      :max-width="900"
      :overlay="isLoading||isPaymentLoading"
    >
      <template #content>
        <AppStepper
          v-model:current-step="currentStep"
          class="checkout-stepper"
          :items="getSteps"
          :direction="$vuetify.display.mdAndUp ? 'horizontal' : 'vertical'"
          align="center"
          is-active-step-valid
        />
        <VDivider />
        <VCardText>
          <!-- 👉 stepper content -->
          <VWindow
            v-model="currentStep"
            class="disable-tab-transition"
            :touch="false"
          >
            <VWindowItem>
              <h4 class="text-h4  ms-2">
                Logistics Service Fee
              </h4>
              <VSheet border>
                <VTable density="compact">
                  <tbody>
                    <tr>
                      <td>
                        Payment Description
                      </td>
                      <td>
                        Navlungo and Customs data service expenditure deposit
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Navlungu Deposit
                      </td>
                      <td>
                        4380 $
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Total Price
                      </td>
                      <td class="font-weight-bold">
                        {{ deposit.balance }} $
                      </td>
                    </tr>
                  </tbody>
                </VTable>
              </VSheet>
            </VWindowItem>

            <VWindowItem>
              <VSheet border>
                <VList
                  nav
                  :lines="false"
                >
                  <VListItem
                    v-for="payment in paymentMethods"
                    :key="payment.value"
                    :value="payment.value"
                    :active="selectedPaymentMethod === payment.value"
                    @click="selectedPaymentMethod=payment.value"
                  >
                    <template #prepend>
                      <VImg
                        class="me-2"
                        :src="payment.image"
                        width="50"
                      />
                    </template>
                    <template #append>
                      <VIcon
                        class="me-2"
                        icon="tabler-chevron-right"
                        width="60"
                        height="60"
                      />
                    </template>

                    <VListItemTitle class="font-weight-bold text-h5">
                      {{ payment.title }}
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </VSheet>
            </VWindowItem>

            <VWindowItem>
              <div
                v-if="(selectedPaymentMethod === 0||selectedPaymentMethod === 2)&&!isPaymentLoading"
              >
                <div v-if="selectedPayment==0">
                  <div class="d-flex flex-warp">
                    <VCard
                      class="pa-2 ma-2"
                      :disabled="isPaymentLoading"
                      @click="onSelectedPayMethod(1)"
                    >
                      <VImg
                        :src="paytr"
                        width="200"
                        height="100"
                      />
                    </VCard>
                    <VCard
                      class="pa-2 ma-2"
                      :disabled="isPaymentLoading"
                      @click="onSelectedPayMethod(2)"
                    >
                      <VImg
                        :src="paypal"
                        width="200"
                        height="100"
                      />
                    </VCard>
                  </div>
                </div>
                <div
                  v-else-if="selectedPayment==1"
                >
                  <iframe
                    v-if="getIframeSrc"
                    :src="getIframeSrc"
                    frameborder="0"
                    height="600"
                    width="100%"
                  />
                </div>
                <div
                  v-else-if="selectedPayment==2"
                >
                  <iframe
                    v-if="paypalUrl"
                    :src="paypalUrl"
                    frameborder="0"
                    height="600"
                    width="100%"
                  />
                </div>
              </div>
              <div v-if="selectedPaymentMethod === 1">
                <div style="width: 14rem;">
                  <VTextField
                    v-model="uuid"
                    class="ma-2"
                    label="Transaction ID"
                    append-icon="tabler-copy"
                    @click:append="copyToClipboard(uuid)"
                  />
                </div>
                <VTextarea
                  v-model="note"
                  class="ma-2"
                  label="Note"
                />
                <VTable density="compact">
                  <thead>
                    <tr>
                      <th>
                        Bank Name
                      </th>
                      <th>
                        Logo
                      </th>
                      <th>
                        Account Name
                      </th>
                      <th>
                        Account Number
                      </th>
                      <th>
                        Swed NO
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="bank in regionPaments.regionBanks"
                      :key="bank.id"
                      @click="onSelectBank(bank)"
                    >
                      <td>
                        {{ bank.name }}
                      </td>
                      <td>
                        <VImg
                          v-if="bank.image"
                          :src="`/storage/${bank.image.path}`"
                          width="80"
                        />
                        <VImg
                          v-else
                          src="https://via.placeholder.com/220x80"
                          width="80"
                        />
                      </td>
                      <td>
                        {{ bank.account_name }}
                      </td>
                      <td>
                        {{ bank.account_number }}
                      </td>
                      <td>
                        {{ bank.swed_no }}
                      </td>
                    </tr>
                  </tbody>
                </VTable>
              </div>
            </VWindowItem>

            <VWindowItem>
              <VSheet>
                <div class="text-center">
                  <VIcon
                    size="55"
                    color="success"
                    icon="tabler-check"
                  />
                  <p>Ödeme Başarılı</p>
                  <p>Ödemeniz Kontrol edildikten sonra işleminiz Onaylanacaktır</p>
                  <p>Ödeme yaparken açıklama kısmına {{ uuid }} Yazmayı unutmayın</p>
                </div>
                <div v-if="selectedPaymentMethod === 1">
                  <div style="width: 14rem;">
                    <VTextField
                      v-model="uuid"
                      label="Transaction ID"
                      append-icon="tabler-copy"
                      @click:append="copyToClipboard(uuid)"
                    />
                  </div>

                  <VTable density="compact">
                    <thead>
                      <tr>
                        <th>
                          Bank Name
                        </th>
                        <th>
                          Account Name
                        </th>
                        <th>
                          Account Number
                        </th>
                        <th>
                          Swed NO
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="bank in regionPaments.regionBanks"
                        :key="bank.id"
                        @click="onSelectBank(bank)"
                      >
                        <td>
                          {{ bank.name }}
                        </td>
                        <td>
                          {{ bank.account_name }}
                        </td>
                        <td>
                          {{ bank.account_number }}
                        </td>
                        <td>
                          {{ bank.swed_no }}
                        </td>
                      </tr>
                    </tbody>
                  </VTable>
                </div>
              </VSheet>
            </VWindowItem>
          </VWindow>
          <div class="d-flex flex-wrap justify-space-between gap-x-4 mt-6 ma-2">
            <VBtn
              v-if="currentStep!=3"
              color="secondary"
              :disabled="currentStep === 0"
              variant="tonal"
              @click="onPaymentStepBack"
            >
              <VIcon
                icon="tabler-arrow-left"
                start
                class="flip-in-rtl"
              />
              Previous
            </VBtn>

            <VBtn
              v-if="checkoutSteps.length - 1 === currentStep"
              color="primary"
              @click="onSubmit"
            >
              Kapat
            </VBtn>

            <VBtn
              v-else-if="currentStep != 2 || selectedPaymentMethod != 0"
              color="success"
              @click="onPaymentStep"
            >
              Next

              <VIcon
                icon="tabler-arrow-right"
                end
                class="flip-in-rtl"
              />
            </VBtn>
          </div>
        </VCardText>
      </template>
    </XDialog>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="errorMsg"
      @confirm="onOkError"
      @cancel="onOkError"
    />

    <XDialog
      v-model:isDialogVisible="isDeposit"
      title="Deposit"
    >
      <template #content>
        <div class="text-center">
          <VTextField
            v-model="deposit.balance"
            class="ma-2"
            label="Deposit Balance"
            @input="formatFloat(deposit,{key:'balance',max:10000})"
          />
          <VBtn
            :disabled="deposit.balance<0.01"
            @click="onDepositConfirm"
          >
            Ok
          </VBtn>
        </div>
      </template>
    </XDialog>
    <XDialog
      v-model:isDialogVisible="isWithdrawal"
      title="Withdrawal"
    >
      <template #content>
        <div class="text-center">
          <VTextField
            v-model="withdrawal.balance"
            class="ma-2"
            label="Withdrawal Balance"
            @input="formatFloat(withdrawal,{key:'balance',max:(wallet.balance-wallet.locked_balance)})"
          />
          <VTextarea
            v-model="withdrawal.note"
            label="Note"
            class="ma-2"
          />
          <VBtn
            :disabled="withdrawal.balance<0.01"
            @click="onWithdrawalConfirm"
          >
            Ok
          </VBtn>
        </div>
      </template>
    </XDialog>

    <XDialog
      v-model:isDialogVisible="isCancelTrans"
      title="Cancel Transaction"
    >
      <template #content>
        <div class="text-center">
          <VCard
            class="pa-2"
          >
            <VCardTitle>
              Cancel this transaction?
            </VCardTitle>
            <VCardText>
              <VBtn
                color="error"
                @click="onCancelTrans"
              >
                Cancel
              </VBtn>
            </VCardText>
          </VCard>
        </div>
      </template>
    </XDialog>
  </VCard>
</template>


<style scoped>
.text--success{
  color: rgb(var(--v-theme-success));
}
.bg--success{
  background-color: rgb(var(--v-theme-success));
}
</style>
