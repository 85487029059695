<script setup>
import Customer from '@/views/customer.vue'

definePage({
  meta: {
    action: 'read',
    subject: 'dealer-list',
  },
})
</script>

<template>
  <div>
    <Customer :customer-types="['dealer']" />
  </div>
</template>
