<script setup>
import globalS from '../../images/j78h930e_400x400.png'
import global from '../../images/j78h9301e_400x400.png'
import truckS from '../../images/tir.png'
import truck from '../../images/1-12.png'
import parcel from '../../images/1-13.png'
import parcelS from '../../images/cc.png'
import { computed, onMounted, ref, watch } from 'vue'
import { formatFloat, formatNumber } from '@/utils'
import { requiredValidator, requiredValidator2 } from '@/@core/utils/validators'
import { VAutocomplete } from 'vuetify/lib/components/index.mjs'
import _ from 'lodash'
import { dataStore } from "@/store"

definePage({
  meta: {
    action: 'read',
    subject: 'create-post',
  },
})


const data = dataStore()



const selectedReceiverDirectory = ref()
const receiverDirectories = ref([])
const isTextSnackbarVisible=ref(false)
const textSnackbarMessage=ref('')
const isError = ref(false)
const errorMessage = ref('')
const formRef = ref(null)
const formNextRef = ref(null)
const fromCityLoading = ref(false)
const cargoCompaniesLoading = ref(false)
const toCityLoading = ref(false)
const isLoadingPorts = ref(false)
const customs = ref(false)
const isSelectCustom = ref(false)
const isloadingCustoms = ref(false)
const searchCustoms = ref('')
const selectedCargoIndex = ref(0)
const isConstLoading = ref(false)
const isComplateOrder = ref(false)
const isLoading=ref(false)

const getCustomsSearch = computed(() => {
  return customs.value.filter(item => item.kategoriadi.toLowerCase().includes(searchCustoms.value.toLowerCase()))
})

const receiverNameSurnameQuery = ref('')

const getReceiverDirectories = async () => {
  console.log('search')
  try {
    await $api('/post/get_directories', {
      method: 'GET',
      params: {
        region_id: postData.value.receiver.region_id,
        country_id: postData.value.receiver.country_id,
        city_id: postData.value.receiver.city_id,
        namesurname: receiverNameSurnameQuery.value,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        receiverDirectories.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
}


const searchDirectory = _.debounce(() => {
  getReceiverDirectories()

}, 500)


const receiverConsts = ref({
  countries: [],
  cities: [],
  districts: [],
})

const senderConsts = ref({
  countries: [],
  cities: [],
  districts: [],
})

const desiPriceDef = ref([])
const subDesiPriceDef = ref([])



const productTypes = ref([
  { title: 'GS1', value: 0 },
  { title: 'GTIN', value: 1 },
  { title: 'UPC', value: 2 },
  { title: 'EAN', value: 3 },
  { title: 'BARKOD', value: 4 },
])


const receiverTypes = ref([
  { title: 'Corporate', value: 0 },
  { title: 'Invidual', value: 1 },

])

const ports = ref({
  from: [],
  to: [],
})

const containerTypes = ref([
  { title: '20', value: 0 },
  { title: '40', value: 1 },
  { title: '45', value: 2 },
  { title: '75', value: 3 },
])

const tradeTypes = ref([
  { title: 'Import', value: 0 },
  { title: 'Export', value: 1 },
])

const postConsts = ref({
  postMethods: [],
  customsPaymentMethods: [],
  countries: [],
  regions: [],
  carriers: [],
  companyTypes: [],
  user_address: {},
})


const postGoToData = ref({
  fromCites: [],
  toCites: [],
})

const cargoCompanies = ref([])



const cargoTableHeader = computed(() => {
  return [
    {
      title: 'Package No',
      value: 'package_no',
    },
    {
      title: 'Paket adeti',
      value: 'piece',
    },
    {
      title: 'Weight(kg)',
      value: 'weight_kg',
    },
    {
      title: 'Width(cm)',
      value: 'width_cm',
    },
    {
      title: 'Length(cm)',
      value: 'length_cm',
    },
    {
      title: 'Height(cm)',
      value: 'height_cm',
    },
    {
      title: 'Category',
      value: 'category',
    },
    ...(postData.value.postType == 0 ? [{
      title: 'Nace Code',
      value: 'nace_code',
    }] : []),

    {
      title: 'Product Type',
      value: 'product_type',
    },
    {
      title: 'Product ID',
      value: 'product_id',
    },
    {
      title: 'B.Price',
      value: 'b_price',
    },
    ...(postData.value.postType == 0 ? [{
      title: 'Customs ID',
      value: 'customs_id',
    }, {
      title: 'Customs NO',
      value: 'customs_no',
    }, {
      title: 'Customs Tax%',
      value: 'customs_tax',
    }, {
      title: 'Customs Price',
      value: 'customs_price',
    }] : []),
    {
      title: 'Navlungo Price',
      value: 'navlungo_price',
    },
    {
      title: 'Currency',
      value: 'currency',
    },
  ]
})




const postData = ref({
  postType: 0,
  loadType: 0,
  post_method_id: null,
  customs_payment_method_id: null,
  fromCountry: null,
  fromCity: null,
  toCountry: null,
  toCity: null,
  address: '',
  cargoType: 1,
  distance: null,
  cargo_company_id: null,
  region_id: null,
  carrier_id: null,
  tradeType: null,
  country_tax_rate: 0,
  country_tax:0,
  custom_tax_price:0,
  navlungo_price: 0,
  sub_navlungo_price: 0,
  total_price: 0,
  customs_no: '',
  customs_id: '',
  sup_payment: 0,
  ship: {
    exit_port_id: null,
    arrival_port_id: null,
    container_type: null,
  },
  receiver: {
    receiver_type: null,
    namesurname: '',
    companyname: '',
    region_id: null,
    country_id: null,
    city_id: null,
    district_id: null,
    address: '',
    cphone: '',
    tax_no: '',
    tax_org: '',
    phone: '',
  },
  sender: {
    sender_type: 0,
    companyname: '',
    official: '',
    region_id: null,
    country_id: null,
    city_id: null,
    district_id: null,
    company_type: null,
    email: '',
    cphone: '',
    address: '',
    phone: '',
    tax_no: '',
    tax_org: '',
    reg_no: '',
    crs_no: '',
    bank_name: '',
    account_name: '',
    iban: '',
    item_type: '',
    koncimento: '',
    tarex_no: '',
    ioss_no: '',
    recipe_no: '',
    recipe_date: '',
    add_tax_free: '',
    origin: '',
  },
  cargoDatas: [
    {
      package_no: 1,
      piece: 1,
      weight_kg: 0,
      width_cm: 0,
      length_cm: 0,
      height_cm: 0,
      category: null,
      nace_code: '',
      product_type: null,
      product_id: '',
      b_price: '',
      customs_id: '',
      customs_no: '',
      customs_tax: 0,
      customs_price: 0,
      navlungo_price: 0,
      sub_navlungo_price: 0,
      currency: '',
    },
  ],
})


const resetPostData =()=>{
  postData.value = {
    id: null,
    postType: 0,
    loadType: 0,
    post_method_id: null,
    customs_payment_method_id: null,
    fromCountry: null,
    fromCity: null,
    toCountry: null,
    toCity: null,
    address: '',
    cargoType: 1,
    distance: null,
    cargo_company_id: null,
    region_id: null,
    carrier_id: null,
    tradeType: null,
    country_tax_rate: 0,
    country_tax:0,
    custom_tax_price:0,
    navlungo_price: 0,
    sub_navlungo_price: 0,
    total_price: 0,
    customs_id: '',
    sub_payment: 0,
    ship: {
      exit_port_id: null,
      arrival_port_id: null,
      container_type: null,
    },
    receiver: {
      receiver_type: null,
      namesurname: '',
      companyname: '',
      region_id: null,
      country_id: null,
      city_id: null,
      district_id: null,
      address: '',
      cphone: '',
      tax_no: '',
      tax_org: '',
      phone: '',
    },
    sender: {
      sender_type: 0,
      companyname: '',
      official: '',
      region_id: null,
      country_id: null,
      city_id: null,
      district_id: null,
      company_type: null,
      email: '',
      cphone: '',
      address: '',
      phone: '',
      tax_no: '',
      tax_org: '',
      reg_no: '',
      crs_no: '',
      bank_name: '',
      account_name: '',
      iban: '',
      item_type: '',
      koncimento: '',
      tarex_no: '',
      ioss_no: '',
      recipe_no: '',
      recipe_date: '',
      add_tax_free: '',
      origin: '',
    },
    cargoDatas: [
      {
        package_no: 1,
        piece: 1,
        weight_kg: 0,
        width_cm: 0,
        length_cm: 0,
        height_cm: 0,
        category: null,
        nace_code: '',
        product_type: null,
        product_id: '',
        b_price: '',
        customs_id: '',
        customs_no: '',
        customs_tax: 0,
        customs_price: 0,
        navlungo_price: 0,
        sub_navlungo_price: 0,
        currency: '',
      },
    ],
  }
}


const getToCities = computed(() => {
  if (postData.value.postType == 0) {
    return postConsts.value.countries.filter(item => item.id != postData.value.fromCountry)
  }

  return postConsts.value.countries
})

const parcelLoads = [
  { label: 'Belge', icon: 'tabler-mail', value: 1 },
  { label: 'Koli', icon: 'tabler-box', value: 2 },
  { label: 'Sandık', icon: 'tabler-box', value: 3 },
  { label: 'Palet', icon: 'tabler-box', value: 4 },

]

const truckLoads = [
  { label: 'Mega Tır', icon: 'tabler-truck', value: 1 },
  { label: 'Mini/Van', icon: 'tabler-truck-return', value: 2 },
  { label: 'Frigo', icon: 'tabler-truck-delivery', value: 3 },
  { label: 'Konteynir', icon: 'tabler-box-model-2', value: 4 },
]

const posts = [
  { label: 'Global Post' },
  { label: 'Local Post' },
]

const loads = [
  { label: 'Parcel Load' },
  { label: 'Truck Load' },
]

const onPost = async index => {
  if (isComplateOrder.value) {
    return
  }
  postData.value.postType = index
  postData.value.toCountry = null
  postData.value.toCity = null
  if (postData.value.fromCountry && index == 1) {
    postData.value.toCountry = postData.value.fromCountry
    await onToCity()
    if (postData.value.fromCity) {

      postData.value.toCity = postData.value.fromCity
    }

  }

}

const onLoad = index => {
  if (isComplateOrder.value) {
    return
  }

  postData.value.cargoType = 1
  postData.value.loadType = index
  getDistance()
}

const onCargoType = async index => {
  postData.value.cargoType = index.value

  // console.log(postData.value.loadType, index.value)
  if (postData.value.loadType == 1 && index.value == 4) {
    await getPorts()
  }

}


const onAddCargo = () => {

  const lastPackageNo = postData.value.cargoDatas[postData.value.cargoDatas.length - 1].package_no + 1

  postData.value.cargoDatas.push({
    package_no: lastPackageNo,
    piece: 1,
    weight_kg: 0,
    width_cm: 0,
    length_cm: 0,
    height_cm: 0,
    category: '',
    nace_code: '',
    product_type: '',
    product_id: '',
    b_price: '',
    customs_id: '',
    customs_no: '',
    customs_tax: '',
    customs_price: 0,
    navlungo_price: 0,
    sub_navlungo_price: 0,
    currency: null,
  })
}


const getCustoms = async () => {
  isloadingCustoms.value = true
  try {
    await $api('/post/get_customs', {
      method: 'GET',
      params: {
        country_id: postData.value.fromCountry,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        customs.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
  isloadingCustoms.value = false
}


const getPriceDefination = async cargo_company_id => {
  if(!postData.value.toCountry){
    return []
  }
  const toCountry = postConsts.value.countries.find(item => item.id == postData.value.toCountry)
  let ret = []

  console.log("toCountry", {
    company_id: cargo_company_id,
    region_id: toCountry.region_id,
  })

  try {
    await $api('/post/get_price_defination', {
      method: 'GET',
      params: {
        company_id: cargo_company_id,
        region_id: toCountry.region_id,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        console.log("data", response._data)

        ret= response._data
      },
    })
  } catch (error) {
    console.error(error)
  }

  return ret
}

const getConsts = async () => {
  isConstLoading.value = true
  try {
    await $api('/post/consts', {
      method: 'GET',
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        postConsts.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
  isConstLoading.value = false
}

const removeCargo = index => {
  console.log('remove cargo')
  postData.value.cargoDatas.splice(index, 1)
}


const getKoncimentUID = async() => {
  if(data.post){
    postData.value.sender.koncimento = data.post.sender.koncimento

    return
  }
  try {
    await $api('/post/get_koncimento_uuid', {
      method: 'GET',
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        console.log(response._data.uid)
        postData.value.sender.koncimento=response._data.uid

      },
    })
  } catch (error) {
    console.error(error)
  }

}


const onSelectReceiverDirectory=()=>{
  const selectedReceiver = receiverDirectories.value.find(item => item.id == selectedReceiverDirectory.value)
  if(selectedReceiver){
    postData.value.receiver.receiver_type=selectedReceiver.receiver_type
    postData.value.receiver.namesurname=selectedReceiver.namesurname
    postData.value.receiver.companyname=selectedReceiver.companyname
    postData.value.receiver.tax_no=selectedReceiver.tax_no
    postData.value.receiver.tax_org=selectedReceiver.tax_org
    postData.value.receiver.phone=selectedReceiver.phone
    postData.value.receiver.cphone=selectedReceiver.cphone
    postData.value.receiver.address=selectedReceiver.address
  }
  console.log(selectedReceiverDirectory.value)
}

const onSubmit = async () => {
  const ret = await formRef.value.validate()

  console.log(ret.valid)
  if (ret.valid) {
    selectedReceiverDirectory.value = null
    receiverDirectories.value = []
    isLoading.value=true

    if(data.post){
      postData.value.receiver.receiver_type=data.post.receiver.receiver_type
      postData.value.receiver.namesurname=data.post.receiver.namesurname
      postData.value.receiver.companyname=data.post.receiver.companyname
      postData.value.receiver.tax_no=data.post.receiver.tax_no
      postData.value.receiver.tax_org=data.post.receiver.tax_org
      postData.value.receiver.phone=data.post.receiver.phone
      postData.value.receiver.cphone=data.post.receiver.cphone

    }

    const selectedCountry =postConsts.value.countries.find(v=>v.id==postData.value.toCountry)

    if(selectedCountry){
      postData.value.receiver.country_id=selectedCountry.id

      const selectedRegion=postConsts.value.regions.find(v=>v.id==selectedCountry.region_id)

      const selectedCity= postGoToData.value.toCites.find(v=>v.id==postData.value.toCity)
      if(selectedCity){
        postData.value.receiver.city_id=selectedCity.id
      }


      if(selectedRegion){
        postData.value.receiver.region_id=selectedRegion.id
      }
    }
    postData.value.receiver.address=postData.value.address


    await getKoncimentUID()
    if(data.post){
      postData.value.sender.tax_no=data.post.sender.tax_no
    }


    const userData =await getUserData()
    if(userData){
      if(userData.registration_type=='individual'){
        postData.value.sender.sender_type=1
      }else{
        postData.value.sender.sender_type=0
        postData.value.sender.companyname=userData.main_company.name
      }
      postData.value.sender.official=userData.name+' '+userData.surname
      postData.value.sender.email=userData.email

      if(userData.main_bank_account){
        postData.value.sender.bank_name=userData.main_bank_account.bank_name
        postData.value.sender.account_name=userData.main_bank_account.account_name
        postData.value.sender.iban=userData.main_bank_account.IBAN
      }
      if(userData.main_address){
        postData.value.sender.cphone=userData.main_address.phone_number
        postData.value.sender.address=userData.main_address.full_address
        postData.value.sender.phone=userData.main_address.phone_number
        postData.value.sender.region_id=userData.main_address.region_id
        await onSenderRegion()
        postData.value.sender.country_id=userData.main_address.country_id
        await onSenderCountry()
        postData.value.sender.city_id=userData.main_address.city_id
        await onSenderCity()
        postData.value.sender.district_id=userData.main_address.district_id
      }
    }
    isComplateOrder.value = true
    console.log(postConsts.value.user_address)
    console.log(postData.value)
    isLoading.value=false
  }
}

const onSubmitNext = async () => {
  const ret = await formNextRef.value.validate()

  console.log(postConsts.value.user_address)
  console.log(ret.valid)
  if (ret.valid) {

    postData.value.tradeType =getTradeType.value
    postData.value.custom_tax_price = parseFloat(topCustomsPriceDef.value)
    postData.value.navlungo_price = parseFloat(topNavlungoPrice.value)
    postData.value.sub_navlungo_price = parseFloat(topSubNavlungoPrice.value)
    postData.value.total_price = parseFloat(ToplamPrice.value)
    isLoading.value=true
    try {
      await $api('/post/save_post', {
        method: 'POST',
        body:postData.value,


        onResponseError({ response }) {
          console.log(response)
        },
        async onResponse({ response }) {
          console.log(response._data)

          textSnackbarMessage.value=response._data.success
          isTextSnackbarVisible.value=true
          isComplateOrder.value = false
          resetPostData()

        },
      })
    } catch (error) {
      console.error(error)
    }
    isLoading.value=false

  }

}


const onCargoCompanies = async () => {
  if (!postData.value.fromCountry || !postData.value.toCountry) {
    return
  }
  const selectedCountry = postConsts.value.countries.find(item => item.id == postData.value.fromCountry)

  if (!selectedCountry) {
    return
  }
  postData.value.cargo_company_id = null
  postData.value.region_id = null
  postData.value.carrier_id = null
  postData.value.tradeType = null
  cargoCompaniesLoading.value = true
  try {

    if (postData.value.fromCountry) {
      await $api('/post/cargo_companies', {
        method: 'GET',
        params: {
          from_country_id: postData.value.fromCountry,
          to_country_id: postData.value.toCountry,
          region_id: selectedCountry.region_id,
          carrier_id: postData.value.fromCountry == postData.value.toCountry ? 2 : 1,
        },
        onResponseError({ response }) {
          console.log(response)
        },
        async onResponse({ response }) {

          cargoCompanies.value = response._data
          if (cargoCompanies.value.length === 0) {
            errorMessage.value = 'There is no cargo company for this route'
            isError.value = true
          }


        },
      })
    }
  } catch (error) {
    console.error(error)
  }
  cargoCompaniesLoading.value = false
}


const getPorts = async () => {
  isLoadingPorts.value = true

  try {
    await $api('/post/get_ports', {
      method: 'POST',
      body: {
        from_country_id: postData.value.fromCountry,
        to_country_id: postData.value.toCountry,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        ports.value = response._data
      },
    })
  } catch (error) {
    console.error(error)
  }
  isLoadingPorts.value = false
}

const onFromCity = async () => {

  postData.value.toCity = null
  postData.value.toCountry = null
  try {
    postGoToData.value.fromCites = []
    if (postData.value.postType == 1) {
      postData.value.toCountry = postData.value.fromCountry
    }
    postData.value.fromCity = null
    fromCityLoading.value = true
    onCargoCompanies()
    if (postData.value.fromCountry) {
      const ret = await $api('/post/cities', {
        method: 'GET',
        params: {
          country_id: postData.value.fromCountry,
        },
        onResponseError({ response }) {
          console.log(response)
        },
        async onResponse({ response }) {
          postGoToData.value.fromCites = response._data
          if (postData.value.postType == 1) {
            postGoToData.value.toCites = response._data
          }
        },
      })
    }
  } catch (error) {
    console.error(error)
  }
  fromCityLoading.value = false
}


const getDistance = async () => {
  if ((!postData.value.fromCity || !postData.value.toCity) || postData.value.loadType == 0) {
    return
  }
  try {

    const ret = await $api('/post/get_distance', {
      method: 'GET',
      params: {
        from_city_id: postData.value.fromCity,
        to_city_id: postData.value.toCity,
      },
      onResponseError({ response }) {
        console.log(response)
      },
      async onResponse({ response }) {
        postData.value.distance = (response._data.distance / 1000).toFixed(2)



      },
    })

  } catch (error) {
    console.error(error)
  }
  toCityLoading.value = false
}

const onToCity = async item => {
  console.log(item)
  try {
    postGoToData.value.toCites = []
    postData.value.toCity = null
    toCityLoading.value = true
    onCargoCompanies()
    if (postData.value.toCountry) {
      const ret = await $api('/post/cities', {
        method: 'GET',
        params: {
          country_id: postData.value.toCountry,
        },
        onResponseError({ response }) {
          console.log(response)
        },
        async onResponse({ response }) {
          postGoToData.value.toCites = response._data


        },
      })
    }
  } catch (error) {
    console.error(error)
  }
  toCityLoading.value = false
}

const init=async()=>{
  await getConsts()
  if(data.post){
    console.log(data.post)
    postData.value.id=data.post.id
    postData.value.address=data.post.address
    postData.value.postType=data.post.post_type
    postData.value.loadType=data.post.load_type
    postData.value.post_method_id=data.post.post_method_id
    postData.value.sup_payment=data.post.sup_payment
    postData.value.customs_no=data.post.customs_no
    if(data.post.customs_payment_method_id){
      postData.value.customs_payment_method_id=data.post.customs_payment_method_id
    }
    postData.value.fromCountry=data.post.fromCountry
    await onFromCity()
    postData.value.fromCity=data.post.fromCity
    if( data.post.toCountry){
      postData.value.toCountry=data.post.toCountry
      await onToCity()
    }
    postData.value.toCity=data.post.toCity
    postData.value.cargoType=data.post.cargoType
    postData.value.cargo_company_id=data.post.cargo_company_id
    await onCargoCompany()
    if(data.post.load_type==1){
      await getDistance()
      if(data.post.cargoType==4){
        await getPorts()
      }

    }
    postData.value.cargoDatas=[]
    data.post.cargo_items.forEach((item,index)=>{
      postData.value.cargoDatas.push({
        id: item.id,
        package_no: item.package_no,
        piece: item.piece,
        weight_kg: item.weight_kg,
        width_cm: item.width_cm,
        length_cm: item.length_cm,
        height_cm: item.height_cm,
        category: item.category,
        nace_code: item.nace_code,
        product_type: item.product_type,
        product_id: item.product_id,
        b_price: item.b_price,
        customs_id: item.customs_id,
        customs_no: item.customs_no,
        customs_tax: item.customs_tax,
        customs_price: item.customs_price,
        navlungo_price: item.navlungo_price,
        sub_navlungo_price: item.sub_navlungo_price,
        currency: item.currency,
      })
      calculateCustomPrice(postData.value.cargoDatas[index])
    })


  }

}

onMounted(() => {
  init()

})

const onCargoCompany = async () => {


  const cargoCompany = cargoCompanies.value.find(item => item.id == postData.value.cargo_company_id)

  console.log(cargoCompany)
  if (!cargoCompany) {
    return
  }
  const region = postConsts.value.regions.find(item => item.id == cargoCompany.region_id)
  if (region) {
    postData.value.region_id = region.id
  }
  console.log("Kargo",cargoCompany)
  desiPriceDef.value =await getPriceDefination(postData.value.cargo_company_id)
  if(cargoCompany.cargo_company){
    console.log("arakargo",cargoCompany.cargo_company)
    subDesiPriceDef.value =await getPriceDefination(cargoCompany.cargo_company.id)

  }



  const carrier = postConsts.value.carriers.find(item => item.id == cargoCompany.carrier_id)
  if (carrier) {
    postData.value.carrier_id = carrier.id
  }

  postData.value.cargoDatas.forEach(item => {
    calculateCustomPrice(item)
  })

  if (postData.value.loadType == 1 && postData.value.cargoType == 4) {
    await getPorts()
  }
}

const getLoads = computed(() => {
  if (postData.value.loadType == 0) {
    return parcelLoads
  }
  if (postData.value.postType == 1) {
    return truckLoads.filter(item => item.value != 4)
  }

  return truckLoads
})



const getTradeType = computed(() => {

  if (postData.value.fromCountry && postData.value.toCountry && postData.value.region_id && postConsts.value.user_address) {
    const fromCountry = postConsts.value.countries.find(item => item.id == postData.value.fromCountry)
    const toCountry = postConsts.value.countries.find(item => item.id == postData.value.toCountry)

    if (postData.value.postType == 0) {
      if (postConsts.value.user_address.region_id != fromCountry.region_id) {
        if (postConsts.value.user_address.region_id == toCountry.region_id) {
          return tradeTypes.value[0].value
        }
        if (fromCountry.region_id == toCountry.region_id) {
          return tradeTypes.value[0].value
        } else {
          return tradeTypes.value[1].value
        }
      }

      return tradeTypes.value[1].value
    }

    return null
  }

  return null
})

const onSelectCustoms = async index => {
  selectedCargoIndex.value = index
  await getCustoms()
  searchCustoms.value = ''
  isSelectCustom.value = true
}

const onSelectCustom = custom => {
  const gumruk_kimligi=custom.gumruk_kimligi.split(',')

  if(gumruk_kimligi.length!=postData.value.region_id-1){
    postData.value.cargoDatas[selectedCargoIndex.value].customs_id = gumruk_kimligi[postData.value.region_id-1]
    postData.value.customs_id = gumruk_kimligi[postData.value.region_id-1]
  }

  console.log(gumruk_kimligi,postData.value.region_id)
  postData.value.cargoDatas[selectedCargoIndex.value].category = custom.kategoriadi
  postData.value.cargoDatas[selectedCargoIndex.value].nace_code = custom.gumruk_nace_kodu

  postData.value.cargoDatas[selectedCargoIndex.value].customs_no = custom.gumruk_no
  postData.value.cargoDatas.customs_no = custom.gumruk_no
  postData.value.cargoDatas[selectedCargoIndex.value].customs_tax = custom.gumruk_vergi_orani
  isSelectCustom.value = false
  calculateCustomPrice(postData.value.cargoDatas[selectedCargoIndex.value])
}

const calculateCustomPrice = cargoData => {
  const fromCountry = postConsts.value.countries.find(item => item.id == postData.value.fromCountry)

  if (fromCountry.region_id == 1) {
    cargoData.currency = "EUR"
  } else {
    cargoData.currency = "USD"
  }

  if (!cargoData.b_price || !cargoData.piece) {
    return
  }

  const basePrice = parseFloat(cargoData.b_price) || 0
  const piece = parseInt(cargoData.piece) || 0
  const taxRate = parseFloat(cargoData.customs_tax) || 0

  cargoData.customs_price = ((basePrice * piece * (1 + taxRate / 100)) - basePrice).toFixed(2)
  let Pdesi_b = 0
  if (postData.value.carrier_id == 1) {
    Pdesi_b = 3000
  } else {
    Pdesi_b = 5000
  }
  let desiMax = 0
  let desiSubMax = 0
  let hacim = cargoData.length_cm * cargoData.width_cm * cargoData.height_cm
  let desi = (hacim) / Pdesi_b
  let desiSub = (hacim) / 5000
  if (desi >= cargoData.weight_kg) {
    desiMax = desi * cargoData.piece
  } else {
    desiMax = cargoData.weight_kg * cargoData.piece
  }
  if (desiSub >= cargoData.weight_kg) {
    desiSubMax = desiSub * cargoData.piece
  } else {
    desiSubMax = cargoData.weight_kg * cargoData.piece
  }



  let gumruk_vergi_oranis = (cargoData.b_price * cargoData.customs_tax) / 100
  const navlungo_price = desiPriceDef.value.find(item => item.desi_min <= desiMax && item.desi_max >= desiMax)
  const sub_navlungo_price = subDesiPriceDef.value.find(item => item.desi_min <= desiMax && item.desi_max >= desiSubMax)

  //navlungu price hesaplama
  if (navlungo_price) {

    cargoData.navlungo_price = (parseFloat(navlungo_price.price)+((parseFloat(navlungo_price.price)*parseFloat(navlungo_price.profit_rate))/100)).toFixed(2)
  } else {
    cargoData.navlungo_price = 0
  }

  //subnavlungu price hesaplama
  if(sub_navlungo_price){
    console.log(sub_navlungo_price)
    cargoData.sub_navlungo_price = (parseFloat(sub_navlungo_price.price)+((parseFloat(sub_navlungo_price.price)*parseFloat(sub_navlungo_price.profit_rate))/100)).toFixed(2)
    postData.value.country_tax_rate=parseFloat(sub_navlungo_price.tax)
    postData.value.country_tax=(parseFloat(postData.value.country_tax_rate) * parseFloat(postData.value.country_tax_rate) / 100).toFixed(2)

  }else{
    cargoData.sub_navlungo_price = 0
  }


  cargoData.customs_price = (gumruk_vergi_oranis).toFixed(2)

}

const onBack = () => {
  isComplateOrder.value = false
}


const topNavlungoPrice = computed(() => {
  return postData.value.cargoDatas.reduce((acc, item) => {


    return (parseFloat(acc) + parseFloat(item.navlungo_price || 0)).toFixed(2)
  }, 0)
})

const topSubNavlungoPrice = computed(() => {
  return postData.value.cargoDatas.reduce((acc, item) => {
    return (parseFloat(acc) + parseFloat(item.sub_navlungo_price || 0)).toFixed(2)
  }, 0)
})





const topCustomsPrice = computed(() => {
  return postData.value.cargoDatas.reduce((acc, item) => {
    return ((parseFloat(acc) + parseFloat(item.customs_price || 0)) / postData.value.cargoDatas.length).toFixed(2)
  }, 0)
})

const topCustomsPriceDef = computed(() => {
  return postData.value.cargoDatas.reduce((acc, item) => {
    return (parseFloat(acc) + parseFloat(item.customs_price)).toFixed(2)
  }, 0)
})


const ToplamPrice = computed(() => {
  return (parseFloat(topSubNavlungoPrice.value)+parseFloat(topNavlungoPrice.value) + parseFloat(topCustomsPrice.value)+parseFloat(postData.value.country_tax)+parseFloat(postData.value.sup_payment)).toFixed(2)
})

const onTest = async() => {

  postData.value.postType = 0
  postData.value.loadType = 0
  postData.value.post_method_id = 1
  postData.value.customs_payment_method_id = 1
  postData.value.fromCountry = 213
  await onFromCity()
  postData.value.fromCity = 6410
  postData.value.toCountry = 77
  await onToCity()
  postData.value.toCity = 2292
  postData.value.address = 'test'
  postData.value.cargo_company_id=cargoCompanies.value[0].id
  postData.value.sender.tax_no="12345678901"


  const cargoCompany = cargoCompanies.value.find(item => item.id == postData.value.cargo_company_id)

  console.log(cargoCompany)
  if (!cargoCompany) {
    return
  }
  const region = postConsts.value.regions.find(item => item.id == cargoCompany.region_id)
  if (region) {
    postData.value.region_id = region.id
  }
  console.log("Kargo",cargoCompany)
  desiPriceDef.value =await getPriceDefination(postData.value.cargo_company_id)
  if(cargoCompany.cargo_company){
    console.log("arakargo",cargoCompany.cargo_company)
    subDesiPriceDef.value =await getPriceDefination(cargoCompany.cargo_company.id)

  }



  const carrier = postConsts.value.carriers.find(item => item.id == cargoCompany.carrier_id)
  if (carrier) {
    postData.value.carrier_id = carrier.id
  }

  postData.value.cargoDatas.forEach(item => {
    calculateCustomPrice(item)
  })

  if (postData.value.loadType == 1 && postData.value.cargoType == 4) {
    await getPorts()
  }

  postData.value.cargoDatas[0].piece=2
  postData.value.cargoDatas[0].weight_kg=2
  postData.value.cargoDatas[0].width_cm=22
  postData.value.cargoDatas[0].length_cm=22
  postData.value.cargoDatas[0].height_cm=22
  postData.value.cargoDatas[0].b_price=33
  postData.value.cargoDatas[0].product_type=2
  postData.value.cargoDatas[0].product_id=2
  postData.value.cargoDatas[0].currency='USD'
  postData.value.cargoDatas[0].customs_tax=2

  await getCustoms()
  searchCustoms.value = ''

  const custom ={
    "id": 1,
    "katid": 103,
    "kategoriadi": "Elektronik",
    "urun_kimligi": 43265,
    "ustkategoriid": null,
    "bolge": "2",
    "ulke_id": 213,
    "gumruk_kimligi": "GTIP,EUORI,HTS,AS,AFK",
    "gumruk_no": 94730001,
    "gumruk_nos": 0,
    "gumruk_vergi_orani": 10,
    "gumruk_nace_kodu": "test test",
    "sira": null,
    "aktif": null,
    "created_at": null,
    "updated_at": null,
  }

  await onSelectCustom(custom)

  const gumruk_kimligi=custom.gumruk_kimligi.split(',')

  if(gumruk_kimligi.length!=postData.value.region_id-1){
    postData.value.cargoDatas[selectedCargoIndex.value].customs_id = gumruk_kimligi[postData.value.region_id-1]

  }

  console.log(gumruk_kimligi,postData.value.region_id)
  postData.value.cargoDatas[selectedCargoIndex.value].category = custom.kategoriadi
  postData.value.cargoDatas[selectedCargoIndex.value].nace_code = custom.gumruk_nace_kodu

  postData.value.cargoDatas[selectedCargoIndex.value].customs_no = custom.gumruk_no
  postData.value.cargoDatas[selectedCargoIndex.value].customs_tax = custom.gumruk_vergi_orani
  isSelectCustom.value = false
  calculateCustomPrice(postData.value.cargoDatas[selectedCargoIndex.value])
}

const onDistanceCity = async () => {

  await getDistance()

}

const getCountry = async region_id => {
  let ret2 = []
  await $api('/post/get_countries', {
    method: 'GET',
    params: {
      region_id: region_id,
    },
    onResponseError({ response }) {
      console.log(response)
    },
    async onResponse({ response }) {
      ret2 = response._data
    },
  })

  return ret2
}

const getCities = async country_id => {
  let ret2 = []
  await $api('/post/get_cities', {
    method: 'GET',
    params: {
      country_id: country_id,
    },
    onResponseError({ response }) {
      console.log(response)
    },
    async onResponse({ response }) {
      ret2 = response._data
    },
  })

  return ret2
}

const getDistricts = async city_id => {
  let ret2 = []
  await $api('/post/get_districts', {
    method: 'GET',
    params: {
      city_id: city_id,
    },
    onResponseError({ response }) {
      console.log(response)
    },
    async onResponse({ response }) {
      ret2 = response._data
    },
  })

  return ret2
}



const getUserData = async () => {
  let ret = null
  await $api('/post/get_user_data', {
    method: 'GET',
    onResponseError({ response }) {
      console.log(response)
    },
    async onResponse({ response }) {
      ret = response._data
    },
  })

  return ret
}

const onReceiverRegion = async () => {

  const data = await getCountry(postData.value.receiver.region_id)

  postData.value.receiver.country_id = null
  receiverConsts.value.countries = data
}

const onReceiverCountry = async () => {
  const data = await getCities(postData.value.receiver.country_id)

  postData.value.receiver.city_id = null
  receiverConsts.value.cities = data
}

const onReceiverCity = async () => {
  const data = await getDistricts(postData.value.receiver.city_id)

  postData.value.receiver.district_id = null
  receiverConsts.value.districts = data
}


const onSenderRegion = async () => {

  const data = await getCountry(postData.value.sender.region_id)

  postData.value.sender.country_id = null
  senderConsts.value.countries = data
}

const onSenderCountry = async () => {
  const data = await getCities(postData.value.sender.country_id)

  postData.value.sender.city_id = null
  senderConsts.value.cities = data

}

const onSenderCity = async () => {
  const data = await getDistricts(postData.value.sender.city_id)

  postData.value.sender.district_id = null
  senderConsts.value.districts = data
}
</script>


<template>
  <div>
    <VCard class="pa-1">
      <VRow>
        <VCol
          v-for="(post, index) in posts"
          :key="index"
          cols="6"
        >
          <VCard
            :color="index === postData.postType ? 'primary' : ''"
            class="text-center"
            @click="onPost(index)"
          >
            <VRow class="ma-1 align-center justify-center">
              <img
                :src="index === postData.postType ? global : globalS"
                alt="icon"
                height="50"
                class="icon"
              >
              <div
                :class="index === postData.postType ? 'text-h4 text-white' : 'text-h4'"
                class="text-h4"
              >
                {{ post.label }}
              </div>
            </VRow>
          </VCard>
        </VCol>
      </VRow>


      <VRow>
        <VCol
          v-for="(load, index) in loads"
          :key="index"
          cols="6"
        >
          <VCard
            :color="index === postData.loadType ? 'primary' : ''"
            class="text-center"
            @click="onLoad(index)"
          >
            <VRow class="ma-1 align-center justify-center">
              <img
                :src="index === postData.loadType ? (index==1?truck : parcelS): index==0?parcel : truckS"
                alt="icon"
                height="50"
                class="icon"
              >
              <div
                :class="index === postData.loadType ? 'text-h4 text-white' : 'text-h4'"
                class="text-h4"
              >
                {{ load.label }}
              </div>
            </VRow>
          </VCard>
        </VCol>
      </VRow>
      <VForm
        v-if="!isComplateOrder"
        ref="formRef"
        @submit.prevent="onSubmit"
      >
        <VRow>
          <VCol
            cols="12"
            sm="6"
          >
            <VSelect
              v-model="postData.post_method_id"
              label="Gönderi Yöntemi"
              :loading="isConstLoading"
              :items="postConsts.postMethods"
              item-title="name"
              item-value="id"
              :rules="[requiredValidator]"
            />
          </VCol>
          <VCol
            v-if="postData.postType == 0"
            cols="12"
            sm="6"
          >
            <VSelect
              v-model="postData.customs_payment_method_id"
              label="Gümrük Ödeme Yöntemi"
              :loading="isConstLoading"
              :items="postConsts.customsPaymentMethods"
              item-title="name"
              item-value="id"
              :rules="[requiredValidator]"
            />
          </VCol>
        </VRow>
        <VRow class="mb-1">
          <VCol cols="6">
            <VCard
              variant="outlined"
              class="pa-2"
            >
              <div class="text-h4 text-center">
                Nereden
              </div>
              <VAutocomplete
                v-model="postData.fromCountry"
                :disabled="postConsts.countries.length === 0"
                :loading="isConstLoading"
                :items="postConsts.countries"
                item-title="trisim"
                item-value="id"
                autocomplete="new-password"
                class="mb-2"
                label="Country"
                :rules="[requiredValidator]"
                @update:model-value="onFromCity"
              />
              <VAutocomplete
                v-model="postData.fromCity"
                :loading="fromCityLoading"
                :items="postGoToData.fromCites"
                item-title="name"
                item-value="id"
                autocomplete="new-password"
                label="City"
                :rules="[requiredValidator]"
                @update:model-value="onDistanceCity"
              />
            </VCard>
          </VCol>
          <VCol cols="6">
            <VCard
              variant="outlined"
              class="pa-2"
            >
              <div class="text-h4 text-center">
                Nereye
              </div>
              <VAutocomplete
                v-model="postData.toCountry"
                :disabled="postData.postType == 1"
                :loading="isConstLoading"
                class="mb-2"
                :items="getToCities"
                item-title="trisim"
                item-value="id"
                autocomplete="new-password"
                label="Country"
                :rules="[requiredValidator]"
                @update:model-value="onToCity($event)"
              />
              <VAutocomplete
                v-model="postData.toCity"
                :items="postGoToData.toCites"
                :loading="toCityLoading"
                label="City"
                item-title="name"
                item-value="id"
                autocomplete="new-password"
                :rules="[requiredValidator]"
                @update:model-value="onDistanceCity"
              />
            </VCard>
          </VCol>
        </VRow>
        <VTextarea
          v-model="postData.address"
          label="Address"
          autocomplete="new-password"
          :rules="[requiredValidator]"
        />
        <VDivider class="ma-2" />
        <VTable style="height: 210px;">
          <thead>
            <tr>
              <th
                :style="(postData.postType == 1 && postData.loadType == 1) ? 'width: 300px;' : 'width: 400px;'"
              >
                Ne Gönderiyorsunuz
              </th>
              <th />
              <th />
              <th />
              <th />
              <th v-if="postData.postType == 0" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  class="ma-2"
                  :style="(postData.postType == 1 && postData.loadType == 1) ? 'width: 300px;' : 'width: 400px;'"
                >
                  <VRow>
                    <VCol
                      v-for=" (cargoType, index) in getLoads"
                      :key="index"
                      :cols="postData.postType == 1 && postData.loadType == 1 ? '4' : '3'"
                    >
                      <VCard
                        class="text-center d-flex flex-column align-items-center justify-center"
                        width="80"
                        height="80"
                        :color="cargoType.value === postData.cargoType ? 'primary' : ''"
                        @click="onCargoType(cargoType)"
                      >
                        <div class="text-center">
                          <VIcon
                            :icon="cargoType.icon"
                            size="50"
                          />
                        </div>
                        <span class="text-h5">{{ cargoType.label }} </span>
                      </VCard>
                    </VCol>
                  </VRow>
                </div>
              </td>
              <td>
                <div style="width: 14rem;">
                  <VSelect
                    v-model="postData.cargo_company_id"
                    :loading="cargoCompaniesLoading"
                    label="Lojistik Firması"
                    :items="cargoCompanies"
                    item-title="name"
                    item-value="id"
                    :rules="[requiredValidator]"
                    @update:model-value="onCargoCompany"
                  />
                  <VSelect
                    v-if="postData.loadType == 1 && postData.cargoType == 4"
                    v-model="postData.ship.exit_port_id"
                    label="Exit Port"
                    :disabled="!postData.cargo_company_id"
                    class="mt-1"
                    :items="ports.from"
                    item-title="name"
                    item-value="id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 12rem;">
                  <VSelect
                    v-model="postData.region_id"
                    readonly
                    :items="postConsts.regions"
                    item-title="short_code"
                    item-value="id"
                    label="Region"
                    :rules="[requiredValidator]"
                  />
                  <VSelect
                    v-if="postData.loadType == 1 && postData.cargoType == 4"
                    v-model="postData.ship.arrival_port_id"
                    :disabled="!postData.cargo_company_id"
                    label="Arrival Port"
                    class="mt-1"
                    :items="ports.to"
                    item-title="name"
                    item-value="id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 12rem;">
                  <VSelect
                    v-model="postData.carrier_id"
                    label="Carrier Type"
                    :items="postConsts.carriers"
                    item-title="name"
                    item-value="id"
                    readonly
                    :rules="[requiredValidator]"
                  />
                  <VSelect
                    v-if="postData.loadType == 1 && postData.cargoType == 4"
                    v-model="postData.ship.container_type"
                    class="mt-1"
                    :disabled="!postData.cargo_company_id"
                    label="Container Type"
                    item-title="title"
                    item-value="value"
                    :items="containerTypes"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div
                  v-if="postData.loadType == 1"
                  style="width: 12rem;"
                >
                  <VTextField
                    v-model="postData.distance"
                    label="Distance"
                    suffix="KM"
                    readonly
                    :rules="[requiredValidator2]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 12rem;">
                  <VSelect
                    v-if="postData.postType == 0"
                    v-model="getTradeType"
                    item-title="title"
                    item-value="value"
                    readonly
                    :items="tradeTypes"
                    label="Trade Type"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </VTable>
        <VDivider class="ma-2" />
        <VTable>
          <thead>
            <tr>
              <th />
              <th
                v-for="header in cargoTableHeader"
                :key="header.value"
              >
                {{ header.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(cargoData, index) in postData.cargoDatas"
              :key="index"
            >
              <td>
                <VBtn
                  v-if="index == 0"
                  variant="tonal"
                  icon
                  :disabled="!postData.cargo_company_id"
                  @click="onAddCargo"
                >
                  <VIcon icon="tabler-plus" />
                </VBtn>
                <VBtn
                  v-else
                  variant="tonal"
                  color="error"
                  icon
                  :disabled="!postData.cargo_company_id"
                  @click="removeCargo(index)"
                >
                  <VIcon icon="tabler-trash" />
                </VBtn>
              </td>
              <td>
                <div style="width: 5rem;">
                  <VTextField
                    v-model="cargoData.package_no"
                    disabled
                  />
                </div>
              </td>
              <td>
                <div style="width: 5rem;">
                  <VTextField
                    v-model="cargoData.piece"
                    :rules="[requiredValidator]"
                    :disabled="!postData.cargo_company_id"
                    @input="formatNumber(cargoData, { key: 'piece', max: 10000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td>
                <div style="width: 7em;">
                  <VTextField
                    v-model="cargoData.weight_kg"
                    :rules="[requiredValidator]"
                    suffix="KG"
                    :disabled="!postData.cargo_company_id"
                    @input="formatFloat(cargoData, { key: 'weight_kg', max: 1000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td>
                <div style="width: 5rem;">
                  <VTextField
                    v-model="cargoData.width_cm"
                    :rules="[requiredValidator]"
                    :disabled="!postData.cargo_company_id"
                    @input="formatFloat(cargoData, { key: 'width_cm', max: 1000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td>
                <div style="width: 4rem;">
                  <VTextField
                    v-model="cargoData.length_cm"
                    :rules="[requiredValidator]"
                    :disabled="!postData.cargo_company_id"
                    @input="formatFloat(cargoData, { key: 'length_cm', max: 1000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td>
                <div style="width: 4rem;">
                  <VTextField
                    v-model="cargoData.height_cm"
                    :rules="[requiredValidator]"
                    :disabled="!postData.cargo_company_id"
                    @input="formatFloat(cargoData, { key: 'height_cm', max: 1000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td>
                <div style="width: 12rem;">
                  <VBtn
                    :loading="isloadingCustoms"
                    :disabled="!postData.cargo_company_id"
                    @click="onSelectCustoms(index)"
                  >
                    {{ cargoData.category ? cargoData.category : 'Kategori Seç' }}
                  </VBtn>
                </div>
              </td>

              <td v-if="postData.postType == 0">
                <div style="width: 12rem;">
                  <VTextField
                    v-model="cargoData.nace_code"
                    :disabled="!cargoData.category || !postData.cargo_company_id"
                    readonly
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 9rem;">
                  <VSelect
                    v-model="cargoData.product_type"
                    :items="productTypes"
                    :disabled="!postData.cargo_company_id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 9rem;">
                  <VTextField
                    v-model="cargoData.product_id"
                    :disabled="!cargoData.category || !postData.cargo_company_id"
                    :rules="[requiredValidator]"
                    @input="formatNumber(cargoData, { key: 'product_id' })"
                  />
                </div>
              </td>
              <td>
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.b_price"
                    :rules="[requiredValidator]"
                    :disabled="!postData.cargo_company_id"
                    @input="formatFloat(cargoData, { key: 'b_price', max: 1000000 }); calculateCustomPrice(cargoData)"
                  />
                </div>
              </td>
              <td v-if="postData.postType == 0">
                <div style="width: 12rem;">
                  <VTextField
                    v-model="cargoData.customs_id"
                    :disabled="!cargoData.category || !postData.cargo_company_id"
                    readonly
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td v-if="postData.postType == 0">
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.customs_no"
                    readonly
                    :disabled="!postData.cargo_company_id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td v-if="postData.postType == 0">
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.customs_tax"
                    readonly
                    :disabled="!postData.cargo_company_id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td v-if="postData.postType == 0">
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.customs_price"
                    readonly
                    :disabled="!postData.cargo_company_id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.navlungo_price"
                    :disabled="!postData.cargo_company_id"
                    readonly
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
              <td>
                <div style="width: 8rem;">
                  <VTextField
                    v-model="cargoData.currency"
                    readonly
                    :disabled="!postData.cargo_company_id"
                    :rules="[requiredValidator]"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </VTable>
        <div class="d-flex">
          <VSpacer />
          <VCard
            max-width="400"
            density="compact"
            min-width="400"
            class="ma-2"
          >
            <VTable>
              <tbody>
                <tr>
                  <td>
                    Navlun Miktarı
                  </td>
                  <td>
                    {{ topNavlungoPrice }}
                  </td>
                </tr>
                <tr v-if="postData.postType == 0">
                  <td>
                    Ara Navlun Miktarı
                  </td>
                  <td>
                    {{ topSubNavlungoPrice }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Ülke Vergi Oranı%
                  </td>
                  <td>
                    {{ postData.country_tax_rate }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Ülke Vergi Fiyatı
                  </td>
                  <td>
                    {{ postData.country_tax }}
                  </td>
                </tr>

                <tr v-if="postData.postType == 0">
                  <td>
                    Gümrük Vergi
                  </td>
                  <td>
                    {{ topCustomsPriceDef }}
                  </td>
                </tr>
                <tr v-if="postData.postType == 0">
                  <td>
                    Ek Vergi
                  </td>
                  <td>
                    <VTextField
                      v-model="postData.sup_payment"
                      @input="formatFloat(postData,{key:'sup_payment'})"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Genel Toplam
                  </td>
                  <td>
                    {{ ToplamPrice }}
                  </td>
                </tr>
              </tbody>
            </VTable>
            <div class="text-center">
              <VBtn
                :loading="isLoading"
                class="ma-2"
                width="200"
                color="primary"
                type="submit"
              >
                Next
              </VBtn>
            </div>
          </VCard>
        </div>
      </VForm>
      <VForm
        v-else
        ref="formNextRef"
        @submit.prevent="onSubmitNext"
      >
        <VCard
          class="ma-2 pa-2"
          color="primary"
          variant="tonal"
        >
          Alıcı Bilgileri
        </VCard>
        <div class="ma-3">
          <VRow>
            <VCol>
              <VAutocomplete
                v-model="selectedReceiverDirectory"
                :items="receiverDirectories"
                item-title="namesurname"
                item-value="id"
                label="Kayıtlı Alıcı"
                @input="searchDirectory"
                @update:model-value="onSelectReceiverDirectory"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.receiver.receiver_type"
                label="Alıcı Tipi"
                :items="receiverTypes"
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="6">
              <VRow>
                <VCol>
                  <VTextField
                    v-model="postData.receiver.namesurname"
                    label="Yetkili Ad Soyad"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol v-if="postData.receiver.receiver_type==0 ">
                  <VTextField
                    v-model="postData.receiver.companyname"
                    label="Company Name"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.receiver.region_id"
                label="Region"
                :items="postConsts.regions"
                item-title="short_code"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onReceiverRegion"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.receiver.country_id"
                label="Country"
                :items="postConsts.countries"
                item-title="trisim"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onReceiverCountry"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.receiver.city_id"
                label="City"
                :items="postGoToData.toCites"
                item-title="name"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onReceiverCity"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.receiver.district_id"
                label="District"
                :items="receiverConsts.districts"
                item-title="name"
                item-value="id"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VTextField
                v-model="postData.receiver.address"
                label="Adres"
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.receiver.cphone"
                v-mask="'(###) ### ## ##'"
                label="Sabit Telefon"
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="6">
              <VRow>
                <VCol>
                  <VTextField
                    v-model="postData.receiver.tax_no"
                    label="Vergi No"
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol v-if="postData.receiver.receiver_type==0 ">
                  <VTextField
                    v-model="postData.receiver.tax_org"
                    label="Vergi Kurumu"
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.receiver.phone"
                v-mask="'(###) ### ## ##'"
                label="Cep Telefonu"
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>
        </div>
        <VCard
          class="ma-2 pa-2"
          color="primary"
          variant="tonal"
        >
          Gönderici Bilgileri
        </VCard>
        <div class="ma-3">
          <VRow>
            <VCol cols="6">
              <VSelect
                v-model="postData.sender.sender_type"
                label="Sender Type"
                readonly
                :items="receiverTypes"
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="6">
              <VRow>
                <VCol v-if="postData.sender.sender_type==0 ">
                  <VTextField
                    v-model="postData.sender.companyname"
                    label="Gönderici Firma"
                    readonly
                    :rules="[requiredValidator]"
                  />
                </VCol>
                <VCol>
                  <VTextField
                    v-model="postData.sender.official"
                    label="Yetkili"
                    readonly
                    :rules="[requiredValidator]"
                  />
                </VCol>
              </VRow>
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.sender.region_id"
                label="Region"
                :items="postConsts.regions"
                item-title="short_code"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onSenderRegion"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.sender.country_id"
                label="Country"
                :items="senderConsts.countries"
                item-title="trisim"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onSenderCountry"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.sender.city_id"
                label="City"
                :items="senderConsts.cities"
                item-title="name"
                item-value="id"
                readonly
                :rules="[requiredValidator]"
                @update:model-value="onSenderCity"
              />
            </VCol>
            <VCol>
              <VSelect
                v-model="postData.sender.district_id"
                label="District"
                readonly
                :items="senderConsts.districts"
                item-title="name"
                item-value="id"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol
              v-if="postData.sender.sender_type==0 "
              cols="3"
            >
              <VSelect
                v-model="postData.sender.company_type"
                :items="postConsts.companyTypes"
                item-title="name"
                item-value="id"
                label="Şirket Türü"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol cols="3">
              <VTextField
                v-model="postData.sender.email"
                label="Yazışma Maili"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="postData.sender.cphone"
                label="Sabit Telefon"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>

          <VRow>
            <VCol>
              <VTextField
                v-model="postData.sender.address"
                label="Çıkış Adresi"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.phone"
                label="Telefon"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>

          <VRow>
            <VCol>
              <VTextField
                v-model="postData.sender.tax_no"
                label="Vergi No"
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol v-if="postData.sender.sender_type==0 ">
              <VTextField

                v-model="postData.sender.tax_org"
                label="Vergi Kurumu"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol v-if="postData.sender.sender_type==0 ">
              <VTextField
                v-model="postData.sender.reg_no"
                label="Sicil No"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol v-if="postData.sender.sender_type==0 ">
              <VTextField
                v-model="postData.sender.crs_no"
                label="Mersis No"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.bank_name"
                label="Bank Name"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.account_name"
                label="Hesap Adı"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.iban"
                label="IBAN"
                readonly
                :rules="[requiredValidator]"
              />
            </VCol>
          </VRow>

          <VRow>
            <VCol>
              <VTextField
                v-model="postData.sender.item_type"
                label="Eşya Türü"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.koncimento"
                label="Konçimento"
                readonly
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.tarex_no"
                label="Tarex No"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.ioss_no"
                label="IOSS Number"
              />
            </VCol>
          </VRow>

          <VRow>
            <VCol>
              <VTextField
                v-model="postData.sender.recipe_no"
                label="Fatura Numarası"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.recipe_date"
                label="Fatura Tarihi"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.add_tax_free"
                label="Ek Vergi Bedeli"
              />
            </VCol>
            <VCol>
              <VTextField
                v-model="postData.sender.origin"
                label="Menşei"
              />
            </VCol>
          </VRow>
        </div>


        <div class="d-flex ma-2">
          <VBtn
            prepend-icon="tabler-arrow-back"
            @click="onBack"
          >
            Back
          </VBtn>
          <VSpacer />
          <VBtn
            append-icon="tabler-arrow-forward"
            color="success"
            type="submit"
          >
            Create
          </VBtn>
        </div>
      </VForm>

      <XDialog
        v-model:isDialogVisible="isSelectCustom"
        title="Kategori Seç"
      >
        <template #content>
          <div
            style="width: 12rem;"
            class="ma-2"
          >
            <VTextField
              v-model="searchCustoms"
              label="Search"
              clearable
            />
          </div>
          <VTable
            density="compact"
            hover
          >
            <thead>
              <tr>
                <th>
                  Id
                </th>
                <th>
                  Kategori
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="custom in getCustomsSearch "
                :key="custom.id"
                @click="onSelectCustom(custom)"
              >
                <td>
                  {{ custom.id }}
                </td>
                <td>
                  {{ custom.kategoriadi }}
                </td>
              </tr>
            </tbody>
          </VTable>
        </template>
      </XDialog>
    </VCard>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="errorMessage"
    />
    <VSnackbar
      v-model="isTextSnackbarVisible"
      variant="flat"
      location="top center"
      color="success"
    >
      {{ textSnackbarMessage }}
    </VSnackbar>
  </div>
</template>
