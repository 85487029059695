import _definePage_default_0 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/access-control.vue?definePage&vue'
import _definePage_default_3 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/accounting.vue?definePage&vue'
import _definePage_default_4 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-companies.vue?definePage&vue'
import _definePage_default_5 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-payments.vue?definePage&vue'
import _definePage_default_6 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-region-list.vue?definePage&vue'
import _definePage_default_7 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cost-table-list.vue?definePage&vue'
import _definePage_default_8 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/create-post.vue?definePage&vue'
import _definePage_default_9 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/customer.vue?definePage&vue'
import _definePage_default_10 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dashboard.vue?definePage&vue'
import _definePage_default_11 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealer-display.vue?definePage&vue'
import _definePage_default_12 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealer-list.vue?definePage&vue'
import _definePage_default_13 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealers-and-discounts.vue?definePage&vue'
import _definePage_default_14 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-cargo-country.vue?definePage&vue'
import _definePage_default_15 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-cargo-regions.vue?definePage&vue'
import _definePage_default_16 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-price-definition.vue?definePage&vue'
import _definePage_default_17 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/docs.vue?definePage&vue'
import _definePage_default_18 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/forgot-password.vue?definePage&vue'
import _definePage_default_19 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/login.vue?definePage&vue'
import _definePage_default_20 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/not-authorized.vue?definePage&vue'
import _definePage_default_21 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/post-list.vue?definePage&vue'
import _definePage_default_22 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/register.vue?definePage&vue'
import _definePage_default_23 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/tracker.vue?definePage&vue'
import _definePage_default_24 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/wallet-balance.vue?definePage&vue'
import _definePage_default_25 from 'C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/wallet-transactions.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/accounting',
    name: 'accounting',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/accounting.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/cargo-companies',
    name: 'cargo-companies',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-companies.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/cargo-payments',
    name: 'cargo-payments',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-payments.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  _mergeRouteRecord(
  {
    path: '/cargo-region-list',
    name: 'cargo-region-list',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargo-region-list.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/cargocountry',
    name: 'cargocountry',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cargocountry.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/cost-table-list',
    name: 'cost-table-list',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/cost-table-list.vue'),
    /* no children */
  },
  _definePage_default_7
  ),
  _mergeRouteRecord(
  {
    path: '/create-post',
    name: 'create-post',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/create-post.vue'),
    /* no children */
  },
  _definePage_default_8
  ),
  _mergeRouteRecord(
  {
    path: '/customer',
    name: 'customer',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/customer.vue'),
    /* no children */
  },
  _definePage_default_9
  ),
  _mergeRouteRecord(
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dashboard.vue'),
    /* no children */
  },
  _definePage_default_10
  ),
  _mergeRouteRecord(
  {
    path: '/dealer-display',
    name: 'dealer-display',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealer-display.vue'),
    /* no children */
  },
  _definePage_default_11
  ),
  _mergeRouteRecord(
  {
    path: '/dealer-list',
    name: 'dealer-list',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealer-list.vue'),
    /* no children */
  },
  _definePage_default_12
  ),
  _mergeRouteRecord(
  {
    path: '/dealers-and-discounts',
    name: 'dealers-and-discounts',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/dealers-and-discounts.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  _mergeRouteRecord(
  {
    path: '/desi-cargo-country',
    name: 'desi-cargo-country',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-cargo-country.vue'),
    /* no children */
  },
  _definePage_default_14
  ),
  _mergeRouteRecord(
  {
    path: '/desi-cargo-regions',
    name: 'desi-cargo-regions',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-cargo-regions.vue'),
    /* no children */
  },
  _definePage_default_15
  ),
  _mergeRouteRecord(
  {
    path: '/desi-price-definition',
    name: 'desi-price-definition',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/desi-price-definition.vue'),
    /* no children */
  },
  _definePage_default_16
  ),
  _mergeRouteRecord(
  {
    path: '/docs',
    name: 'docs',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/docs.vue'),
    /* no children */
  },
  _definePage_default_17
  ),
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_18
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/login.vue'),
    /* no children */
  },
  _definePage_default_19
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_20
  ),
  _mergeRouteRecord(
  {
    path: '/post-list',
    name: 'post-list',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/post-list.vue'),
    /* no children */
  },
  _definePage_default_21
  ),
  {
    path: '/regionlist',
    name: 'regionlist',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/regionlist.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/register.vue'),
    /* no children */
  },
  _definePage_default_22
  ),
  {
    path: '/test',
    name: 'test',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/test.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/tracker',
    name: 'tracker',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/tracker.vue'),
    /* no children */
  },
  _definePage_default_23
  ),
  _mergeRouteRecord(
  {
    path: '/wallet-balance',
    name: 'wallet-balance',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/wallet-balance.vue'),
    /* no children */
  },
  _definePage_default_24
  ),
  _mergeRouteRecord(
  {
    path: '/wallet-transactions',
    name: 'wallet-transactions',
    component: () => import('C:/Users/GPX/Desktop/malldia/lojistik-malldia/resources/js/pages/wallet-transactions.vue'),
    /* no children */
  },
  _definePage_default_25
  )
]
