<script setup>
import { requiredValidator } from '@/@core/utils/validators'
import { get } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'

definePage({
  meta: {
    action: 'read',
    subject: 'wallet-transactions',
  },
})

const wallets = ref([])
const region = ref(0)
const regions=ref([])
const tabIndex=ref(0)
const selectedItem=ref({})
const isProcessing=ref(false)
const isLoading=ref(false)
const regionPaments=ref({ regionBanks:[],regionPayments:[] })
const isBank=ref(false)
const isCard=ref(false)
const selectedPaymentItem=ref({ region_id:null ,name:'',status:1 })
const isDeletePayment=ref(false)
const deletePaymentMessage=ref('')

const isDeleteBank=ref(false)
const deleteBankMessage=ref('')
const selectedBankItem=ref({ region_id:null ,name:'',account_name:'',account_number:'',swed_no:'',status:1 })
const isAddBank=ref(false)
const bankFormRef=ref()
const paymentFormRef=ref()
const uploadImage=ref()
const fileInput=ref()
const selectFile = ref()
const isAddCard=ref(false)
const isUserDetail=ref(false)
const selectedUserWallet=ref({})
const userDetailTab=ref(0)

const paymentTypes = [
  { title: 'Paytr', value: 0 },

  // { title: 'Paypal', value: 2 },
]

const getData=async ()=>{
  isLoading.value=true
  await $api('wallet_transactions', {
    method: 'POST',
    body: {
      region_id: region.value,
    },
    onResponse: ({ response }) => {
      wallets.value = response._data
    },
  })
  isLoading.value=false
}

watch(region,()=>{
  getData()
})



const getRegions=async()=>{
  isLoading.value=true
  await $api('get_regions', {
    method: 'GET',

    onResponse: ({ response }) => {
      console.log(response)
      regions.value = response._data
    },
  })
  isLoading.value=false
}


onMounted(()=>{
  getRegions()
  getData()
})


const incomingHeaders=computed(()=>{
  return [
    { title: 'Process', value: 'process' },
    { title: 'Uid', value: 'uuid' },
    { title: 'Region', value: 'region' },
    { title: 'Wallet Balance', value: 'wallet_balance' },
    { title: 'User Name', value: 'user_name' },
    { title: 'Date Time', value: 'transaction_date' },
    { title: 'Amount', value: 'amount' },
    { title: 'Description', value: 'description' },
    { title: 'Payment Method', value: 'payment_method' },
    { title: 'Status', value: 'status' },


  ]
})

const outgoingHeaders=computed(()=>{
  return [
    { title: 'Process', value: 'process' },
    { title: 'Uid', value: 'uuid' },
    { title: 'Region', value: 'region' },
    { title: 'Wallet Balance', value: 'wallet_balance' },
    { title: 'User Name', value: 'user_name' },
    { title: 'Date Time', value: 'transaction_date' },
    { title: 'Amount', value: 'amount' },
    { title: 'Description', value: 'description' },
    { title: 'Status', value: 'status' },


  ]
})


const bankHeaders = [
  { title: 'Process', value: 'process',width: '80px' },
  { title: 'Image', value: 'image' },
  { title: 'Region', value: 'region.short_code' },
  { title: 'Name', value: 'name' },
  { title: 'Account Name', value: 'account_name' },
  { title: 'Account number', value: 'account_number' },
  { title: 'SWED NO', value: 'swed_no' },
  { title: 'Status', value: 'status' },

]

const cardHeaders = [
  { title: 'Process', value: 'process',width: '80px' },
  { title: 'Image', value: 'image' },
  { title: 'Region', value: 'region.short_code' },
  { title: 'Name', value: 'name' },
  { title: 'Status', value: 'status' },

]



const getPaymentStatus = status => {
  switch (status) {
  case 0:
    return 'Pending'
  case 1:
    return 'Approved'
  case 2:
    return 'Rejected'
  case 3:
    return 'Canceled'
  default:
    return 'Pending'
  }
}


const getIncomingTrans = computed(() => {
  return wallets.value.filter(v=>v.type==1)
})

const getOutgoingTrans = computed(() => {
  return wallets.value.filter(v=>v.type==2)
})


const onAction=item=>{
  selectedItem.value=item
  isProcessing.value=true
  console.log(item)
}

const onActionConfirm=async status=>{
  isProcessing.value=false
  isLoading.value=true
  await $api('transaction_process', {
    method: 'POST',
    body: {
      transaction_id: selectedItem.value.id,
      status: status,
    },
    onResponse: ({ response }) => {
      getData()
    },
  })
  isLoading.value=false
}

const getBaks=async()=>{
  isLoading.value=true
  await $api('get_banks', {
    method: 'GET',

    onResponse: ({ response }) => {
      console.log(response)
      regionPaments.value.regionBanks = response._data
    },
  })
  isLoading.value=false
}


const getCards=async()=>{
  isLoading.value=true
  await $api('get_payments', {
    method: 'GET',

    onResponse: ({ response }) => {
      console.log(response)
      regionPaments.value.regionPayments = response._data

    },
  })
  isLoading.value=false
}

const onMenuClick=async type=>{
  if(type==1){
    await getCards()
    isCard.value=true
  }else{
    await getBaks()
    isBank.value=true
  }
}


const onActionPayment=async(action,item)=>{
  if(item.image){
    uploadImage.value = `/storage/${item.image.path}`
  }else{
    uploadImage.value = null
  }

  if(action=='edit'){
    selectedPaymentItem.value={ ...item }
    isAddCard.value=true

  }else if(action=='delete'){
    isDeletePayment.value=true
    selectedPaymentItem.value=item
    deletePaymentMessage.value=`Are you sure you want to delete ${item.name}`

  }else if(action=='delete_confirm'){
    isLoading.value=true
    await $api('payment_type/delete', {
      method: 'GET',
      params: {
        id: item.id,
      },
      onResponse: async ({ response }) => {
        await getCards()
      },
    })
    isLoading.value=false

  }else if(action=='active'){
    isLoading.value=true
    await $api('payment_type/status', {
      method: 'GET',
      params: {
        id: item.id,
      },
      onResponse: async ({ response }) => {
        await getCards()
      },
    })
    isLoading.value=false
  }

  console.log(item)
}


const onActionBank=async(action,item)=>{
  if(item.image){
    uploadImage.value = `/storage/${item.image.path}`
  }else{
    uploadImage.value = null
  }

  if(action=='edit'){
    selectedBankItem.value={ ...item }
    isAddBank.value=true

  }else if(action=='delete'){
    isDeleteBank.value=true
    selectedBankItem.value=item
    deleteBankMessage.value=`Are you sure you want to delete ${item.name}`

  }else if(action=='delete_confirm'){
    isLoading.value=true
    await $api('bank/delete', {
      method: 'GET',
      params: {
        id: item.id,
      },
      onResponse: async ({ response }) => {
        await getBaks()
      },
    })
    isLoading.value=false

  }else if(action=='active'){
    isLoading.value=true
    await $api('bank/status', {
      method: 'GET',
      params: {
        id: item.id,
      },
      onResponse: async ({ response }) => {
        await getBaks()
      },
    })
    isLoading.value=false
  }

  console.log(item)
}

const onBankSave=async ()=>{
  const resp=await   bankFormRef.value?.validate()
  if(resp.valid){
    isAddBank.value=false
    isLoading.value=true

    const formData = new FormData()
    for (const key in selectedBankItem.value) {
      formData.append(key, selectedBankItem.value[key])
    }

    if (selectFile.value) {
      formData.append('file', selectFile.value)
    }

    await $api('bank/edit', {
      method: 'POST',
      body: formData,
      onResponse: async ({ response }) => {
        await getBaks()
      },
    })
    isLoading.value=false
  }
  console.log(resp)
}


const onAddBank=()=>{
  selectedBankItem.value={ region_id:null ,name:'',account_name:'',account_number:'',swed_no:'',status:1 }
  uploadImage.value=null
  isAddBank.value=true
}

const onPaymentBank=()=>{
  selectedPaymentItem.value={ region_id:null,type:null ,name:'',status:1 }
  uploadImage.value=null
  isAddCard.value=true
}

const triggerFileInput = () => {
  fileInput.value.click()
}

const handleFileUpload = event => {
  const file = event.target.files[0]
  if (file) {
    selectFile.value = file

    const reader = new FileReader()

    reader.onload = e => {
      uploadImage.value = e.target.result
    }
    reader.readAsDataURL(file)
  }
}

const onPaymentSave=async()=>{
  const resp=await paymentFormRef.value?.validate()

  console.log(resp)
  if(resp.valid){
    isAddCard.value=false
    isLoading.value=true

    const formData = new FormData()
    for (const key in selectedPaymentItem.value) {
      formData.append(key, selectedPaymentItem.value[key])
    }

    if (selectFile.value) {
      formData.append('file', selectFile.value)
    }
    $api('payment_type/edit', {
      method: 'POST',
      body: formData,
      onResponse: async ({ response }) => {
        await getCards()
      },
    })
    isLoading.value=false
  }
}

const onUserDetail=item=>{

  selectedUserWallet.value={ ...item.wallet }
  console.log(selectedUserWallet.value)
  isUserDetail.value=true
}
</script>


<template>
  <VCard>
    <div class="d-flex flex-warp gap-2 ma-2">
      <div style="width: 8rem;">
        <VSelect
          v-model="region"
          label="Region"
          :items="[{short_code:'All',id:0},...regions]"
          item-title="short_code"
          item-value="id"
        />
      </div>
      <VSpacer />
      <VBtn>
        Payment Settings
        <VMenu
          activator="parent"
          width="240"
          location="bottom end"
          offset="12px"
        >
          <VList>
            <VListItem @click="onMenuClick(1)">
              Payment Method List
            </VListItem>
            <VListItem @click="onMenuClick(2)">
              Bank List
            </VListItem>
          </VList>
        </VMenu>
      </VBtn>
    </div>
    <VTabs v-model="tabIndex">
      <VTab>
        Incoming
      </VTab>
      <VTab>
        Outgoing
      </VTab>
    </VTabs>
    <VDataTable
      :items="tabIndex==0?getIncomingTrans :getOutgoingTrans "
      :headers="tabIndex==0?incomingHeaders : outgoingHeaders "
    >
      <template #[`item.status`]="{ item }">
        <VChip
          :color="item.status === 0 ? 'warning' : item.status === 1? 'success' : item.status === 2?'info' : 'error'"
          class="text-capitalize"
        >
          {{ getPaymentStatus(item.status) }}
        </VChip>
      </template>
      <template #[`item.process`]="{ item }">
        <VBtn
          v-if="item.status==0&&item.payment_method_id!=1 "
          class="me-2"
          variant="tonal"
          color="success"
          size="small"
          icon="tabler-hand-click"
          @click="onAction(item)"
        />
      </template>

      <template #[`item.payment_method`]="{ item }">
        {{ item.payment_method.name }}
      </template>
      <template #[`item.region`]="{ item }">
        {{ item.region.short_code }}
      </template>
      <template #[`item.user_name`]="{ item }">
        <VBtn
          variant="tonal"
          @click="onUserDetail(item)"
        >
          {{ `${item.wallet.user.name} ${item.wallet.user.surname}` }}
        </VBtn>
      </template>
    </VDataTable>
    <XDialog
      v-model:isDialogVisible="isProcessing"
    >
      <template #content>
        <div class="text-center">
          <VBtn
            class="ma-2"
            color="success"
            @click="onActionConfirm(1)"
          >
            Approve
          </VBtn>
          <VBtn
            class="ma-2"
            color="error"
            @click="onActionConfirm(2)"
          >
            Reject
          </VBtn>
        </div>
      </template>
    </XDialog>
    <XDialog
      v-model:isDialogVisible="isBank"
      title="Bank List"
      :max-width="1200"
    >
      <template #content>
        <div class="d-flex flex-wrap gap-2 ma-2">
          <VBtn
            append-icon="tabler-plus"
            @click="onAddBank"
          >
            Add
          </VBtn>
        </div>
        <VDataTable
          :items="regionPaments.regionBanks"
          :headers="bankHeaders"
        >
          <template #[`item.process`]="{ item }">
            <VRow>
              <VBtn
                class="me-2"
                variant="tonal"
                color="primary"
                size="small"
                icon="tabler-edit"
                @click="onActionBank('edit',item)"
              />
              <VBtn
                class="me-2"
                variant="tonal"
                color="error"
                size="small"
                icon="tabler-trash"
                @click="onActionBank('delete',item)"
              />
            </VRow>
          </template>
          <template #[`item.status`]="{ item }">
            <VChip
              :color="item.status === 0 ? 'error' : 'success'"
              class="text-capitalize"
              @click="onActionBank('active',item)"
            >
              {{ item.status==1?'Active':'Inactive' }}
            </VChip>
          </template>
          <template #[`item.image`]="{ item }">
            <VImg
              v-if="item.image"
              :src="`/storage/${item.image.path}`"
              width="80"
            />
            <VImg
              v-else
              src="https://via.placeholder.com/220x80"
              width="80"
            />
          </template>
        </VDataTable>
      </template>
    </XDialog>


    <XDialog
      v-model:isDialogVisible="isCard"
      title="Card List"
      :max-width="1200"
    >
      <template #content>
        <div class="d-flex flex-wrap gap-2 ma-2">
          <VBtn
            append-icon="tabler-plus"
            @click="onPaymentBank"
          >
            Add
          </VBtn>
        </div>
        <VDataTable
          :items="regionPaments.regionPayments"
          :headers="cardHeaders"
        >
          <template #[`item.process`]="{ item }">
            <VRow>
              <VBtn
                class="me-2"
                variant="tonal"
                color="primary"
                size="small"
                icon="tabler-edit"
                @click="onActionPayment('edit',item)"
              />
              <VBtn
                class="me-2"
                variant="tonal"
                color="error"
                size="small"
                icon="tabler-trash"
                @click="onActionPayment('delete',item)"
              />
            </VRow>
          </template>
          <template #[`item.status`]="{ item }">
            <VChip
              :color="item.status === 0 ? 'error' : 'success'"
              class="text-capitalize"
              @click="onActionBank('active',item)"
            >
              {{ item.status==1?'Active':'Inactive' }}
            </VChip>
          </template>

          <template #[`item.image`]="{ item }">
            <VImg
              v-if="item.image"
              :src="`/storage/${item.image.path}`"
              width="80"
            />
            <VImg
              v-else
              src="https://via.placeholder.com/220x80"
              width="80"
            />
          </template>
        </VDataTable>
      </template>
    </XDialog>

    <OkDialog
      v-model:isDialogVisible="isDeleteBank"
      :confirmation-msg="deleteBankMessage"
      no-enable
      @confirm="onActionBank('delete_confirm',selectedBankItem)"
    />
    <OkDialog
      v-model:isDialogVisible="isDeletePayment"
      :confirmation-msg="deletePaymentMessage"
      no-enable
      @confirm="onActionPayment('delete_confirm',selectedPaymentItem)"
    />

    <XDialog
      v-model:isDialogVisible="isAddCard"
      :title="selectedPaymentItem.id ? ' Edit Payment' : 'Add Payment' "
      :overlay="isLoading"
    >
      <template #content>
        <VForm
          ref="paymentFormRef"
          @submit.prevent="onPaymentSave"
        >
          <VSelect
            v-model="selectedPaymentItem.type"
            class="ma-2"
            label="Payment Type"
            clearable
            :items="paymentTypes"
          />
          <VCard
            variant="outlined"

            height="100"
            class="cursor-pointer ma-2"
            @click="triggerFileInput"
          >
            <div
              v-if="!uploadImage"
              class="d-flex align-center justify-center flex-column"
              style="height: 100%"
            >
              <VIcon icon="tabler-upload" />
              <p class="mb-0 text-center">
                Upload A Picture
              </p>
            </div>
            <VImg
              :src="uploadImage"
              contain
              height="100"
            />
          </VCard>
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            @change="handleFileUpload"
          >
          <VSelect
            v-model="selectedPaymentItem.region_id"
            :items="regions"
            label="Region"
            item-title="short_code"
            item-value="id"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField

            v-model="selectedPaymentItem.name"
            label="Name"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-if="selectedPaymentItem.type== 0 "
            v-model="selectedPaymentItem.merchant_id"
            label="Merchant Id"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-if="selectedPaymentItem.type== 0 "
            v-model="selectedPaymentItem.merchant_key"
            label="Merchant Key"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-if="selectedPaymentItem.type== 0 "
            v-model="selectedPaymentItem.merchant_salt"
            label="Merchant salt"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <div class="text-center">
            <VBtn

              type="submit"
            >
              Kaydet
            </VBtn>
          </div>
        </VForm>
      </template>
    </XDialog>

    <XDialog
      v-model:isDialogVisible="isAddBank"
      :title="selectedBankItem.id ? ' Edit Bank' : 'Add Bank' "
      :overlay="isLoading"
    >
      <template #content>
        <VForm
          ref="bankFormRef"
          @submit.prevent="onBankSave"
        >
          <VCard
            variant="outlined"

            height="100"
            class="cursor-pointer ma-2"
            @click="triggerFileInput"
          >
            <div
              v-if="!uploadImage"
              class="d-flex align-center justify-center flex-column"
              style="height: 100%"
            >
              <VIcon icon="tabler-upload" />
              <p class="mb-0 text-center">
                Upload A Picture
              </p>
            </div>
            <VImg
              :src="uploadImage"
              contain
              height="100"
            />
          </VCard>
          <input
            ref="fileInput"
            type="file"
            style="display: none"
            @change="handleFileUpload"
          >


          <VSelect
            v-model="selectedBankItem.region_id"
            :items="regions"
            label="Region"
            item-title="short_code"
            item-value="id"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-model="selectedBankItem.name"
            label="Bank Name"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-model="selectedBankItem.account_name"
            label="Account Name"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-model="selectedBankItem.account_number"
            label="Account number"
            class="ma-2"
            :rules="[requiredValidator]"
          />
          <VTextField
            v-model="selectedBankItem.swed_no"
            label="Swed no"
            class="ma-2"
            :rules="[requiredValidator]"
          />

          <div class="text-center">
            <VBtn

              type="submit"
            >
              Kaydet
            </VBtn>
          </div>
        </VForm>
      </template>
    </XDialog>
    <XDialog
      v-model:isDialogVisible="isUserDetail"
      :max-width="800"
    >
      <template #content>
        <div class="text-center">
          <div class="text-h4 ma-2">
            {{ selectedUserWallet.user.name+' '+selectedUserWallet.user.surname }}
          </div>
          <div class="text-h5 ma-2">
            Balance: {{ selectedUserWallet.balance }}
          </div>
          <div
            v-if="selectedUserWallet.locked_balance>0"
            class="text-h5 ma-2"
          >
            Locked Balance: {{ selectedUserWallet.locked_balance }}
          </div>
        </div>
        <VTabs v-model="userDetailTab">
          <VTab>Address</VTab>
          <VTab>Bank</VTab>
        </VTabs>
        <VWindow v-model="userDetailTab">
          <VWindowItem>
            <VTable density="compact">
              <tbody>
                <tr>
                  <td>
                    Region
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_address.region.short_code }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Address
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_address.full_address }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Country
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_address.country.trisim }}
                  </td>
                </tr>
                <tr>
                  <td>
                    City
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_address.city.name }}
                  </td>
                </tr>
                <tr v-if="selectedUserWallet.user.main_address.district">
                  <td>
                    District
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_address.district.name }}
                  </td>
                </tr>
              </tbody>
            </VTable>
          </VWindowItem>

          <VWindowItem>
            <VTable density="compact">
              <tbody>
                <tr>
                  <td>
                    Bank Name
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_bank_account.bank_name }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Account Name
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_bank_account.account_name }}
                  </td>
                </tr>
                <tr>
                  <td>
                    IBAN
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_bank_account.IBAN }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Currency
                  </td>
                  <td>
                    {{ selectedUserWallet.user.main_bank_account.currency }}
                  </td>
                </tr>
              </tbody>
            </VTable>
          </VWindowItem>
        </VWindow>
      </template>
    </XDialog>
  </VCard>
</template>
