<script setup>
definePage({
  meta: {
    action: 'read',
    subject: 'dealer-display',
  },
})
</script>

<template>
  <div>
    dealer display
  </div>
</template>
