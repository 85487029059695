<script setup>
import { ref } from 'vue'
import Definition from '@/views/desi-price-definition/Definition.vue'

const tabIndex = ref(0)

definePage({
  meta: {
    action: 'read',
    subject: 'desi-price-definition',
  },
})
</script>


<template>
  <div>
    <VTabs
      v-model="tabIndex"
      class="v-tabs-pill mb-2"
      grow
    >
      <VTab
        prepend-icon="tabler-forklift"
        class="ma-2"
        variant="tonal"
      >
        Parcel Freight List
      </VTab>
      <VTab
        prepend-icon="tabler-truck"
        class="ma-2"
        variant="tonal"
      >
        Truck Load List
      </VTab>
    </VTabs>
    <Definition :tab-index="tabIndex" />
  </div>
</template>
