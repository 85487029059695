<script setup>
import Payment from '@/views/cargo-payments/payment.vue'

definePage({
  meta: {
    action: 'read',
    subject: 'cargo-payments',
  },
})
</script>

<template>
  <div>
    <Payment />
  </div>
</template>
