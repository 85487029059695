<script setup>
import { requiredValidator } from '@/@core/utils/validators'
import bgimage from '../../images/arkaplan-kargotakip.jpg'
import car from '../../images/arkaplan-araç.png'
import iadecar from '../../images/iade-arac.png'
import { fakeAwait } from '@/utils'
import { onMounted, ref } from 'vue'

definePage({
  meta: {
    action: 'read',
    subject: 'tracker',
  },
})


const trackData =ref({
  tracking:false,
  type:1,
  number: '',
  step: 0,
  datas:[],
})

const isLoading = ref(false)


const tracking = ref({
  number: null,
})

const onSubmit=async()=>{
  console.log('submit')
  isLoading.value=true
  await fakeAwait(500)
  trackData.value={
    tracking:true,
    type:1,
    number: '1234567890',
    step: 0,
    datas:[
      [
        { title:'Bölge', value:'TR/Türkiye',icon:'tabler-map-pin' },
        { title:'Bölge', value:'TR/Türkiye',icon:'tabler-map-pin' },

      ],
      [
        { title:'Gönderen', value:'KE**Y***',icon:'tabler-user-down' },
        { title:'Alıcı', value:'OS**A***',icon:'tabler-user-up' },
      ],
      [
        { title:'Çıkış Tarihi', value:'27 Kasım 2019',icon:'tabler-calendar-stats' },
        { title:'Telim Tarihi', value:'2 Aralık 2019',icon:'tabler-calendar-stats' },
      ],
      [
        { title:'Gönderi Kodu', value:'2421848671629',icon:'tabler-file-barcode' },
        { title:'Teslim Birimi', value:'Yenigirne',icon:'tabler-building-skyscraper' },
      ],
      [
        { title:'Gönderi Tipi', value:'Standart',icon:'tabler-letter-t-small' },
        { title:'Telefon Numarası', value:'02323655253',icon:'tabler-phone' },
      ],
    ],
  }
  isLoading.value=false
}

const steps=ref([
  { id:0, title:'Exit' },
  { id:1, title:'The Way' },
  { id:2, title:'Transfer' },
  { id:3, title:'Arrival' },
  { id:4, title:'Distribution' },
  { id:5, title:'Delivery' },
])

onMounted(()=>{
})

const onPrev=()=>{
  if(trackData.value.step>0){
    trackData.value.step--
  }
}

const onNext=()=>{
  if(trackData.value.step<steps.value.length-1){
    trackData.value.step++
  }
}

const onType=()=>{
  trackData.value.type=trackData.value.type===1?0:1
}
</script>

<template>
  <div>
    <div
      v-show="!trackData.tracking"
      :style="`background-image: url('${bgimage}'); height: calc(100vh - 90px); background-size: cover; background-position: center; background-repeat: no-repeat;`"
    >
      <div class="auth-wrapper d-flex align-center justify-center pa-4">
        <div class="position-relative my-sm-16">
          <VCard
            class="auth-card pa-4"
            max-width="448"
            min-width="348"
          >
            <VCardTitle class="text-center ma-2">
              Kargo Takip
            </VCardTitle>

            <div
              class="text-center"
              style="justify-items: center;"
            >
              <VImg
                :src="car"
                width="100"
                height="100"
              />
            </div>
            <VCardText>
              <VForm
                ref="refVForm"
                @submit.prevent="onSubmit"
              >
                <VRow>
                  <VCol cols="12">
                    <VTextField
                      v-model="tracking.number"
                      :disabled="isLoading"
                      :loading="isLoading"
                      label="Tracking Number"
                      placeholder="Tracking Number"
                      type="name"
                      :rules="[requiredValidator]"
                    />
                  </VCol>


                  <VBtn
                    :loading="isLoading"
                    class="mb-1"
                    :disabled="isLoading"
                    block
                    type="submit"
                  >
                    Sorgula
                  </VBtn>


                  <!-- create account -->
                  <VCol
                    cols="12"
                    class="text-center text-base"
                  />
                </VRow>
              </VForm>
            </VCardText>
          </VCard>
        </div>
      </div>
    </div>
    <VExpandXTransition>
      <div v-show="trackData.tracking">
        <VBtn
          class="ma-2"
          color="error"
          @click="trackData.tracking=false "
        >
          Exit
        </VBtn>
        <VBtn
          class="ma-2"
          @click="onPrev"
        >
          prev
        </VBtn>
        <VBtn
          class="ma-2"
          @click="onNext"
        >
          Next
        </VBtn>
        <VBtn @click="onType">
          Type
        </VBtn>
        <VStepper v-model="trackData.step">
          <VStepperHeader>
            <template
              v-for="(step, index) in steps"
              :key="step.id"
            >
              <VStepperItem
                :title="step.title"
                :value="step.id"
                :color="(trackData.type==1
                  ?(index <= trackData.step)
                  :(index >= trackData.step+1))
                  ?'primary' : 'error'"
                complete
                :complete-icon="(trackData.type==1
                  ?(index <= trackData.step)
                  :(index >= trackData.step+1))
                  ? 'tabler-check' : 'tabler-x'"
              />
              <VImg
                v-if="index == trackData.step&&index !== steps.length "
                :src="trackData.type === 1 ? car : iadecar"
                width="80"
                height="80"
              />
              <VDivider
                v-if="index !== steps.length"
                :color="(trackData.type==1
                  ?(index <= trackData.step)
                  :(index >= trackData.step+1))
                  ?'error' : 'primary' "
                :thickness="9"
                inset
              />
            </template>
          </VStepperHeader>
        </VStepper>

        <VCard>
          <VCardTitle class="text-center">
            {{ steps[trackData.step].title }}
          </VCardTitle>

          <VRow
            v-for="(data,index) in trackData.datas"
            :key="index"
          >
            <VCol
              v-for="(v,i) in data"
              :key="i"
              cols="6"
              class="d-flex gap-2"
              style="justify-content: center;align-items: center;"
            >
              <div
                class="d-flex gap-2"
                style="width: 12rem;"
              >
                <VIcon
                  :icon="v.icon"
                  size="35"
                />
                <div>
                  <h4>{{ v.title }}</h4>
                  <div>{{ v.value }}</div>
                </div>
              </div>
            </VCol>
          </VRow>
        </VCard>
      </div>
    </VExpandXTransition>
  </div>
</template>


<style lang="scss">
@use '@core-scss/template/pages/page-auth.scss';
</style>
