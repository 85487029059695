<script setup>
import Customer from '@/views/customer.vue'


definePage({
  meta: {
    action: 'read',
    subject: 'customer',
  },
})
</script>

<template>
  <div>
    <Customer />
  </div>
</template>
