<script setup>
import { $api } from '@/utils/api'
import { onMounted, ref } from 'vue'

const search = ref('')
const isEdit = ref(false)
const isLoading = ref(false)
const errorMessage = ref('')
const isError = ref(false)

definePage({
  meta: {
    action: 'read',
    subject: 'desi-cargo-regions',
  },
})

const headers = ref([
  { title: 'Process', value: 'process', width: '100px' },
  { title: 'Desiregion', value: 'desiregion', sortable: true },
  { title: 'Status', value: 'status', sortable: true },
])

const items = ref([])

const item = ref({
  id: null,
  desiregion: 1,
  status: 1,
})

const getData = async () => {
  isLoading.value = true
  await $api('desi_region', {
    method: 'GET',
    onResponse: ({ response }) => {
      items.value = response._data
      console.log('Response', items.value)
    },
  })
  isLoading.value = false
}

const onEdit = selectedItem => {
  if (selectedItem) {
    item.value = { ...selectedItem }
  } else {
    item.value = {
      id: null,
      desiregion: null,
      status: 1,
    }
  }
  console.log('Edit', item.value)
  isEdit.value = true
}

onMounted(() => {
  getData()
})

const onSave = async () => {
  isEdit.value = false
  isLoading.value = true

  await $api(`desi_region${item.value.id ? `/${item.value.id}` : ''}`, {
    method: 'POST',
    params: {
      _method: item.value.id ? 'PUT' : 'POST',
    },
    body: item.value,
    onResponse: async ({ response }) => {
      console.log('Response status', response.status)
      if (response.status > 400) {
        isError.value = true
        errorMessage.value = response._data.message

      } else {
        await getData()
      }


    }, onResponseError: ({ response }) => {
      console.log('Error', response)
    },
  })
  isLoading.value = false
}
</script>


<template>
  <div>
    <VCard class="pa-2">
      <div class="d-flex flex-wrap">
        <div style="width: 12rem">
          <VTextField
            v-model="search"
            label="Search"
            clearable
          />
        </div>
        <VSpacer />
        <VBtn
          prepend-icon="tabler-plus"
          @click="onEdit(null)"
        >
          Add
        </VBtn>
      </div>
      <VDataTable
        :loading="isLoading"
        :items="items"
        :headers="headers"
        :search="search"
        hover
        density="compact"
      >
        <template #[`item.status`]="{ item }">
          <VChip :color="item.status == 1 ? 'success' : 'error'">
            {{ item.status == 1 ? 'Active' : 'Passive' }}
          </VChip>
        </template>



        <template #[`item.process`]="{ item }">
          <VBtn
            class="me-2"
            variant="tonal"
            size="small"
            icon="tabler-edit"
            @click="onEdit(item)"
          />
        </template>
      </VDataTable>
    </VCard>
    <XDialog
      v-model:isDialogVisible="isEdit"
      text-center
      :title="item.id ? 'Update Desiregion' : 'Add Desiregion'"
    >
      <template #content>
        <VTextField
          v-model="item.desiregion"
          label="Desiregion"
          class="ma-2"
        />
        <VSwitch
          v-model="item.status"
          label="Status"
          :true-value="1"
          :false-value="0"
          class="ma-2"
        />
        <div class=" text-center">
          <VBtn
            :disabled="!item.desiregion"
            class="ma-2"
            color="primary"
            @click="onSave"
          >
            Save
          </VBtn>
          <VBtn
            class="ma-2"
            color="secondary"
            @click="isEdit = false"
          >
            Close
          </VBtn>
        </div>
      </template>
    </XDialog>
    <OkDialog
      v-model:isDialogVisible="isError"
      :confirmation-msg="errorMessage"
      ok-title="OK"
    />
  </div>
</template>
