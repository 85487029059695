<script setup>
import CargoesList from '@/views/cargo-companies/CargoesList.vue'
import AddCargoForm from '@/views/cargo-companies/AddCargoForm.vue'

definePage({
  meta: {
    action: 'read',
    subject: 'cargo-companies',
  },
})

const currentTab = ref(1)
</script>


<template>
  <VCard>
    <VTabs
      v-model="currentTab"
      grow
      class="v-tabs-pill mb-2"
    >
      <VTab class="ma-2">
        Add Cargo Company
      </VTab>
      <VTab class="ma-2">
        Registered Cargoes
      </VTab>
    </VTabs>


    <AddCargoForm v-if="currentTab == 0" />

    <CargoesList v-if="currentTab == 1" />
  </VCard>
</template>
