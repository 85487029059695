<script setup>
import image1 from '../../images/Untitled-1.png'
import image2 from '../../images/localpost.png'
import image3 from '../../images/Cargo Tracking.png'
import image4 from '../../images/postlist.png'
import image5 from '../../images/marketplacelist.png'
import image6 from '../../images/wallet.png'
import image7 from '../../images/Our Dealers.png'
import image8 from '../../images/Bill of Lading.png'
import image9 from '../../images/Accounting.png'

definePage({
  meta: {
    action: 'read',
    subject: 'dashboard',
  },
})

const items = [
  {
    image: image1,
    title: 'Global Post',
    to: 'create-post',
  },
  {
    image: image2,
    title: 'Local Post',
    to: 'create-post',
  },
  {
    image: image3,
    title: 'Cargo Tracking',
    to: 'tracker',
  },
  {
    image: image4,
    title: 'Post List',
    to: 'post-list',
  },
  {
    image: image5,
    title: 'Marketplace List',
  },
  {
    image: image6,
    title: 'Wallet',
    to: 'wallet-balance',
  },
  {
    image: image7,
    title: 'Our Dealers',
    to: 'dealers-and-discounts',
  },
  {
    image: image8,
    title: 'Bill of Lading',
  },
  {
    image: image9,
    title: 'Accounting',
    to: 'accounting',
  },
]

const hHeight = ref('70vh')

const setTableHeight = async () => {
  const windowHeight = window.innerHeight
  const targetHeight = windowHeight - 90

  hHeight.value = `${targetHeight}`
}

window.addEventListener('resize', setTableHeight)
onMounted(() => {
  setTableHeight()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setTableHeight)
})
</script>


<template>
  <VContainer fluid="">
    <VRow class="text-center">
      <VCol cols="12">
        <h1 style="color: #406dde">
          Malldia Logistic
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <DashBoardItem
        v-for="(item, index) in items"
        v-bind="item"
        :key="index"
      />
    </VRow>
  </VContainer>
</template>
